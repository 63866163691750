import { useEffect, useState, useCallback } from "react";
import '../__pages.css'
import { ENDPOINT, FormData } from "../../data/FormData";
import { useParams } from "react-router";
import { Button, Input, SpaceBetween, Spinner } from "@cloudscape-design/components";
import cipraLogo from "../../res/cipra_logo.jpg";
import phsoLogo from "../../res/phso-logo.png"
import cdxLogo from "../../res/cdx-logo.png"
import h2050Logo from "../../res/h2050-logo.jpg"
import nhcLogo from "../../res/nhc-logo.jpg"
import rxdxLogo from "../../res/rxdx-logo.png"
import cabLogo from '../../res/cab_logo.png'
import { RegistrationData } from "../../data/RegistrationData";
import {support_contact} from "../../res/support"
const support: JSX.Element = support_contact
function RegistrationEntryPage(): JSX.Element{
    const [emailValue, setEmailValue] = useState<string>('')
    const [emailTip, setEmailTip] = useState<JSX.Element | undefined>(undefined)
    const [showBlock, setShowBlock] = useState<boolean>(true)
    const [validProviderAndProgram, setValidProviderAndProgram] = useState<boolean>(true);
    const { provider, program } = useParams()
    const [selectedLanguage, setSelectedLanguage] = useState<string>('')
    const [response, setResponse] = useState<JsonData | null>(null)

    // define the type of jsonData using an interface accroding to the API response
    interface JsonData {
        valid:boolean;
        languages:{language_id: string, language_name:string}[];
    }
    const renderProviderLogo = () => {
        const uppercaseProvider = provider?.toUpperCase()
        if (uppercaseProvider === "CDX") {
            return <img src={cdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (uppercaseProvider === "H2050") {
            return <img src={h2050Logo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (uppercaseProvider === "NHC") {
            return <img src={nhcLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (uppercaseProvider === "PHSO") {
            return <img src={phsoLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (uppercaseProvider === "RXDX") {
            return <img src={rxdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "CAB"){
            return <img src={cabLogo} alt='provider logo' width='90%' className='line-align-center' />;
        }else {
            return <img src={cipraLogo} alt='provider logo' width='90%' className='line-align-center' />;
        }
    };
    const poweredBy = () => {
        const uppercaseProvider = provider?.toUpperCase()
        if (uppercaseProvider === "CDX" || uppercaseProvider === "H2050" || uppercaseProvider === "NHC" || uppercaseProvider === "PHSO" || uppercaseProvider === "RXDX" || provider == "CAB") {
            return (
                <div className='line-align-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ marginRight: '10px' }}>Enabled by:</p>
                    <img src={cipraLogo} alt="Description" style={{ width: 'auto', maxWidth: '100%', height: '30px' }} />
                </div>
            );
        } else {
            return null;
        }
    }
    const [submitWaiting, setSubmitWaiting] = useState<boolean>(false)
    const submit = useCallback((): void => {
        if(emailValue.length == 0){
            setEmailTip(
                <div>
                    Email can't be empty.
                </div>
            )
            return
        }
        if (selectedLanguage.length == 0){
            setEmailTip(
                <div>
                    Please select the lanaguage.
                </div>
            )
            return
        }
        setSubmitWaiting(true)
        RegistrationData.instance.emailExistv2(
            emailValue,
            () => {
                RegistrationData.instance.sendEmail(
                    emailValue,
                    provider!,
                    program!,
                    "",// subscription
                    selectedLanguage,//language
                    () => {
                        setSubmitWaiting(false)
                        setShowBlock(false);
                        setEmailTip(
                            <div style={{color: 'green'}}>
                                Registration Link has been sent to <b>{emailValue}</b>, please check your email inbox for the next step.
                            </div>
                        )
                    },
                    () => {
                        setSubmitWaiting(false)
                        setEmailTip(
                            <div>
                                Request cannot be processed at the moment.
                            </div>
                        )
                    }
                )
            },
            // Email already registered.
            () => {
                setSubmitWaiting(false)
                setEmailTip(
                    <div>
                        <p>This email address <b>{emailValue} has already been registered.</b></p>
                        {support}
                    </div>
                )
            }
        )
    }, [emailValue, selectedLanguage])
    useEffect(() => {
        console.log("loading page")
        // check from api - if the provider or program is valid  
        fetch(`${ENDPOINT}/validproviderprogram/${provider}/${program}`).then(response => {
            if (!response.ok) {// provider is valid
                setValidProviderAndProgram(false)
                setShowBlock(false)
            }
            return response.json()
        })
        .then(data => {
            // Call the provided callback with the parsed JSON content
            setResponse(data)
        })
        .catch(err => {
            console.log(err)
        })

    }, [program, provider])
    function handleLanguageChange(value:string):void{
        setSelectedLanguage(value)
    }
    return (
        <div className="center-screen">
            <div className="glow-border page-container" style={{ maxWidth: "330px" }}>
                <SpaceBetween size='m' direction='vertical'>
                    {renderProviderLogo()}
                    {program && (program.split("_")[0].toUpperCase() === "HTN") &&
                        <div>
                            <h3 className='line-align-center' style={{ marginBottom: '0' }}>Hypertension Remote Monitoring </h3>
                            <h3 className='line-align-center' style={{ margin: '0 0' }}> + Lifestyle Guidance </h3>
                        </div>

                    }
                    {program && (program.split("_")[0].toUpperCase() === "DM") &&
                        <div>
                            <h3 className='line-align-center' style={{ marginBottom: '0' }}>Diabetes Remote Monitoring </h3>
                            <h3 className='line-align-center' style={{ margin: '0 0' }}> + Lifestyle Guidance </h3>
                        </div>
                    }
                    
                    <h2 className='line-align-center'>Enter Email to Receive Registration Link</h2>
                    {
                        (!validProviderAndProgram) &&
                        <div>
                            <p>This is not a valid registration link.</p>
                            {support} 
                        </div>
                    }
                    {
                        (showBlock) && (response) &&
                        <SpaceBetween size='m' direction='vertical'>
                            <SpaceBetween size='m' direction='horizontal'>
                                <div>Email</div>
                            </SpaceBetween>
                            <Input onChange={({ detail }) => setEmailValue(detail.value)}
                                value={emailValue}
                                type="email"
                                placeholder="Email Address"
                            ></Input>
                            {<SpaceBetween size='m' direction='vertical'>
                            <SpaceBetween size='m' direction='horizontal'>
                                <div>Select a Language</div>
                            </SpaceBetween>
                            <select id="languageSelect" onChange={(e) => handleLanguageChange(e.target.value)}
                            style={{ color: 'black', padding: '7px', borderRadius: '7px' , width: '100%', border: '2px solid gray'}}>
                                <option value=""></option>
                                {response.languages.map(languages => (
                                    <option key={languages.language_id} value={languages.language_id}>{languages.language_name}</option>
                                ))}
                            </select>
                        </SpaceBetween>}

                            <div className='line-align-center'>
                                <Button variant='primary' onClick={submit} disabled={submitWaiting}>
                                    {
                                        submitWaiting && <Spinner />
                                    }
                                    Confirm
                                </Button>
                            </div>
                        </SpaceBetween>
                    }
                    {
                        <div>
                           <p className='tip-text'>{emailTip}</p>
                        </div>
                    }
                    {poweredBy()}
                </SpaceBetween>
            </div>
        </div>
    );
}

export default RegistrationEntryPage;
