import cipraLogo from "../../res/cipra_logo.jpg";
import {useEffect, useState} from 'react';
import '../__pages.css'
import {SpaceBetween} from "@cloudscape-design/components";
import {ENDPOINT} from "../../data/FormData";
import {useParams} from "react-router";

function UnsubscribeConfirmationPage() {

  const [showPageContent, setShowPageContent] = useState<JSX.Element>(<div/>);
  const {token} = useParams()

  useEffect(() => {
    async function loadPage(): Promise<void> {
      // check from api - if the token is valid  
      const responseToken = await fetch(`${ENDPOINT}/unsubscribepatient/${token}`
      , {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
      if(!responseToken.ok) {
        const invalidPage: JSX.Element = 
        <div className="center-screen">
        <div className="glow-border page-container" style={{maxWidth: "330px"}}>
            <SpaceBetween size='m' direction='vertical'>
                <img src={cipraLogo} alt='cipra logo' width='90%' className='line-align-center'/>
                <h2 className='line-align-center'>Oops</h2>
                    <div>
                        <p>This link has expired. Please request a new link <a href="http://account.cipra.ai/unsubscribe">here</a>.</p>
                    </div>
            </SpaceBetween>
        </div>
        </div>
        setShowPageContent(invalidPage)
      }else{
        const confirmPage: JSX.Element = 
        <div className="center-screen">
        <div className="glow-border page-container" style={{maxWidth: "330px"}}>
            <SpaceBetween size='m' direction='vertical'>
                <img src={cipraLogo} alt='cipra logo' width='90%' className='line-align-center'/>
                <h2 className='line-align-center'>Successfully unsubscribed</h2>
                    <div>
                    Thank you for being a part of the CIPRA.ai program. Your un-subscription is confirmed.
                    </div>
            </SpaceBetween>
        </div>
        </div>
        setShowPageContent(confirmPage)
      }
      return
    }
    loadPage()
  }, [token])

  return (
    <div className="App center-screen">
      {showPageContent}
    </div>
  );
}

export default UnsubscribeConfirmationPage;
