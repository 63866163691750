import {ENDPOINT} from "./FormData";

export class RegistrationData {
    static instance: RegistrationData = new RegistrationData()

    EndpointEmailExist = `${ENDPOINT}/emailexists/v2/`
    EndpointSendEmail = `${ENDPOINT}/generateregistrationurl?email=` // parameter email
    EndpointResetPassword = `${ENDPOINT}/resetpassword`

    emailExistv2(
        emailAddress: string,
        whenNotExist: (data:any) => void,
        whenExist: () => void
    ): void {
        const url = this.EndpointEmailExist + emailAddress;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                // Call the provided callback with the parsed JSON content
                if (data.email_exists) {
                    whenExist();
                } else {
                    whenNotExist(data);
                }
                
            })
            .catch(err => {
                console.log(err)
            })
    }

    sendEmail(
        emailAddress: string,
        provider:string,
        program:string,
        subscription:string,
        language:string,
        whenSuccess: () => void,
        whenFailed: () => void
    ): void {
        var url;
        console.log("-------")
        console.log(language)
        if (subscription === ""){
            url = this.EndpointSendEmail + emailAddress + "&provider="+provider.toUpperCase() + "&program="+ program.toUpperCase()+"&language_id="+language;
        }else{
            url = this.EndpointSendEmail + emailAddress + "&provider="+provider.toUpperCase() + "&program="+ program.toUpperCase()+ "&subscription=" + subscription+"&language_id="+language;
        }
        console.log(url)
        fetch(url)
            .then(response => {
                if(response.ok){
                    whenSuccess()
                } else {
                    whenFailed()
                }
            })
            .catch(err => {
                console.log(err);
                whenFailed();
            })
    }

    submit(emailAddress: string, password: string, callbackSuccess: () => void, callbackFail: () => void): void {
        const submitData = {
            email: emailAddress,
            password: password
        }
        fetch(this.EndpointResetPassword, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(submitData)
        })
            .then(response => {
                console.log(response)
                callbackSuccess()
            })
            .catch(e => {
                console.log('Network Issue: ' + e)
                callbackFail()
            })
        console.log("submitted", submitData)
    }
}
