import React, { useState } from 'react';
import { DEBUG_MODE, FormData } from "../data/FormData";
export function Terms(): JSX.Element {
    const supportCell = FormData.instance.supportCell;
    const supportEmail = FormData.instance.supportEmail;
    const providerName = FormData.instance.providerName;
    const programName = FormData.instance.programName;
    const term = FormData.instance.provider.toLowerCase() === "other" ?
    "The Service is owned by CIPRA.ai, Inc." :
    `${providerName} offers the Service to certain patients in collaboration with CIPRA.ai, Inc.`;

    return (<div style={{ padding: '10px' }}>
    <h4>
        {providerName} Terms & Conditions
    </h4>

    <p>Effective Date: February 20, 2024</p>
    <p>Welcome to the {providerName} {programName} Management program (the “Service” or “Services”). {term} and its subsidiaries or affiliates involved in providing and supporting the Service (collectively, “we,” “us,” “our,” or “CIPRA.ai”). Please read on to learn the rules and restrictions that govern your use of our website(s), products, and applications for the Service. By accessing or using the Service, including our websites or mobile applications in any way, you agree to be bound by these terms and conditions (the “Agreement”). If you have any questions, comments, or concerns regarding this Agreement or the Service, please contact us at:</p>
    <p>Email: <a href={`mailto:${supportEmail}`}>{supportEmail}</a></p>
    <p>Phone: {supportCell}</p>
    <br></br>
        <p><b>Please read this Agreement and any referenced terms, such as our <a href="https://www.cipra.ai/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>, carefully. The Agreement includes information about future changes to this Agreement, limitations of liability, a class action waiver and resolution of disputes by arbitration instead of in court. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICE IS SUBJECT TO THIS AGREEMENT; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICE IN ANY MANNER.</b></p>
        <p><b>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</b> EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.</p>

        <ol>
            <li><b>Scope of Service</b></li>
            <p>
                This Agreement is a binding contract between you and CIPRA.ai, and applies to your use of the Service. We have other websites and services that may be covered by different terms of use.
                <br></br>
                    The Service provides a variety of content, products and services, including personal activity and blood pressure/glucose monitoring, data analysis, participant access to a personal CIPRA.ai account, personalized recommendations for improving blood pressure/glucose, access to CIPRA.ai's mobile and web-based hypertension and/or diabetes management systems, and technologies, and on call, monitoring, support features, and information provided by CIPRA.ai's staff.
                    <br></br>
                        In order to use the Service, you must currently own and use a supported activity tracker and Bluetooth-enabled blood pressure/glucose monitor, e.g., activity trackers made by Fitbit, Inc. or Apple, Inc., and blood pressure/glucose monitors made by Omron Corporation or iHealth Labs Inc. Your use of such activity trackers and blood pressure/glucose monitors with the Service is additionally subject to any applicable terms of use and privacy policy from each device's manufacturer.
                        <br></br>
                            Features and specifications of products or services described or depicted on the Service are subject to change at any time without notice.
                            <br></br>
                                You may be required to sign up for an account, select a password and user name (“User ID”), and provide us with certain information or data, such as your contact information. You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select as your User ID a name that you do not have the right to use, or another person's name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission.
                                <br></br>
                                    You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you've received your parent's or guardian's permission to use the Service and have gotten your parent or guardian to agree to this Agreement on your behalf).
                                    <br></br>
                                        You will only use the Service for your own internal, personal, non-commercial use, and not on behalf of or for the benefit of any third party, and only in a manner that complies with all laws that apply to you. If your use of the Service is prohibited by applicable laws, then you aren't authorized to use the Services. We can't and won't be responsible for your using the Service in a way that breaks the law.
                                        <br></br>
                                            You will not share your User ID, account or password with anyone, and you must protect the security of your User ID, account, password and any other access tools or credentials. You're responsible for any activity associated with your User ID and account.
            </p>
            <li><b>No Medical Advice; Not for Emergencies</b></li>
            <p>
                <b>CIPRA.ai does not offer medical advice or diagnoses, or engage in the practice of medicine. Our Service is not intended to be a substitute for professional medical advice, diagnosis, or treatment and are offered for informational and communicative purposes only. The Service is not intended to be, and must not be taken to be, the practice of medicine, nursing, pharmacy or other healthcare advice by CIPRA.ai. </b>
                <br></br>
                    <b>The Service is not meant to diagnose or treat any conditions - only your medical professional can determine the right course of treatment for you and determine what is safe, appropriate and effective based on your needs. Reliance on any information provided by CIPRA.ai or in connection with the Service is solely at your own risk. You are solely responsible for any decisions or actions you take based on the information and materials available through the Service. </b>
                    <br></br>
                        <b>You acknowledge that although some Content (defined below) may be provided by individuals in the medical profession, the provision of such Content does not create a medical professional/patient relationship between you and CIPRA.ai or between you and any other individual or entity, and does not constitute an opinion, medical advice, or diagnosis or treatment.</b> Healthcare providers and patients should always obtain applicable diagnostic information from appropriate trusted sources. Healthcare providers should never withhold professional medical advice or delay in providing it because of something they have read in connection with our Services.
                        <br></br>
                            <b>THE SERVICES SHOULD NEVER BE USED AS A SUBSTITUTE FOR EMERGENCY CARE. IF YOU HAVE A MEDICAL OR MENTAL HEALTH EMERGENCY, ARE THINKING ABOUT SUICIDE OR TAKING ACTIONS THAT MAY CAUSE HARM TO YOU OR TO OTHERS, YOU SHOULD SEEK EMERGENCY TREATMENT AT THE NEAREST EMERGENCY ROOM OR DIAL 911.</b>
            </p>
            <li><b>Data Privacy</b></li>
            <p>CIPRA.ai takes the privacy of its users very seriously. For the current CIPRA.ai Privacy Policy, please visit: <a href="https://www.cipra.ai/privacy-policy" target="_blank" rel="noreferrer">https://www.cipra.ai/privacy-policy</a>.</p>
            <p><u>Children's Online Privacy Protection Act</u>. The Children's Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13 years of age. We do not knowingly collect or solicit personally identifiable information from children under 13 years of age; if you are a child under 13 years of age, please do not attempt to register for or otherwise use the Service or send us any personal information. If we learn we have collected personal information from a child under 13 years of age, we will delete that information as quickly as possible. If you believe that a child under 13 years of age may have provided us personal information, please contact us at {supportEmail}</p>
            <li><b>Right to Change This Agreement</b></li>
            <p>
                We are constantly trying to improve our Service, so this Agreement may need to change along with our Service. We reserve the right to change the Agreement at any time, but if we do, we will place a notice on our site located at <a href="https://www.cipra.ai/" target="_blank" rel="noreferrer">https://www.cipra.ai/</a>, send you an email, and/or notify you by some other means.
                <br></br>
                    If you don't agree with the new Agreement, you are free to reject it; unfortunately, that means you will no longer be able to use the Service. If you use the Service in any way after a change to the Agreement is effective, that means you agree to all of the changes.
                    <br></br>
                        Except for changes by us as described here, no other amendment or modification of this Agreement will be effective unless in writing and signed by both you and us.
            </p>
            <li><b>SMS Messaging and Communications</b></li>
            <p>
                As part of the Service, you may receive communications, including messages that CIPRA.ai sends you (for example, via email or SMS). SMS messages may include, for example, data collection reminders or lifestyle recommendations. To stop the SMS text service at any time, text STOP to (844) 914-1333.
                <b>By signing up for the Service and providing us with your wireless number, you confirm that you want CIPRA.ai to send you information regarding your account or transactions with us, which may include CIPRA.ai using automated dialing technology to text you at the wireless number you provided, and you agree to receive communications from CIPRA.ai, and you represent and warrant that each person you register for the Services or for whom you provide a wireless phone number has consented to receive communications from CIPRA.ai. </b>
                You agree to indemnify and hold CIPRA.ai harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to your breach of the foregoing.
            </p>
            <li><b>Rights in the Service</b></li>
            <p>
                The materials displayed or performed or available on or through the Service, including, but not limited to, text, graphics, data, articles, photos, images, illustrations and so forth (all of the foregoing, the “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Service, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else's (including CIPRA.ai's) rights.
                <br></br>
                    Subject to this Agreement, we grant each user of the Service a worldwide, non-exclusive, non-sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the Service. Use, reproduction, modification, distribution or storage of any Content for any purpose other than using the Service is expressly prohibited without prior written permission from us. You understand that CIPRA.ai owns the Service. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this section), create derivative works based on, or otherwise exploit any of the Service. The Service may allow you to copy or download certain Content, but please remember that even where these functionalities exist, all the restrictions in this section still apply.
            </p>
            <li><b>Terminating Use of the Service</b></li>
            <p>
                You are free to terminate your use of the Service at any time by contacting us at <a href={`mailto:${supportEmail}`}>{supportEmail}</a>. CIPRA.ai is also free to terminate (or suspend access to) your use of the Service or your account for any reason in our discretion, including your breach of this Agreement. CIPRA.ai has the sole right to decide whether you are in violation of any of the restrictions set forth in this Agreement.
                <br></br>
                    Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.
                    <br></br>
                        Provisions that, by their nature, should survive termination of this Agreement shall survive termination. By way of example, all of the following will survive termination: any obligation you have to indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.
            </p>
            <li><b>Mobile Applications</b></li>
            <p>
                You acknowledge and agree that the availability of our mobile application is dependent on the third party stores from which you download the application, e.g., the App Store from Apple or the Android app market from Google (each an “App Store”). Each App Store may have its own terms and conditions to which you must agree before downloading mobile applications from such store, including the specific terms relating to Apple App Store set forth below. You agree to comply with, and your license to use our application is conditioned upon your compliance with, such App Store terms and conditions. To the extent such other terms and conditions from such App Store are less restrictive than, or otherwise conflict with, the terms and conditions of this Agreement, the more restrictive or conflicting terms and conditions in this Agreement apply.
                <br></br>
                    <u>Apple App Store</u>. This Agreement applies to your use of the Service, including our iOS applications (the “Application”) available via the Apple, Inc. (“Apple”) App Store, but the following additional terms also apply to the Application:
            </p>
            <ol type="a">
                <li>Both you and CIPRA.ai acknowledge that the Agreement is concluded between you and CIPRA.ai only, and not with Apple, and that Apple is not responsible for the Application or the Content;</li>
                <li>The Application is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in connection with the Service for your private, personal, non-commercial use, subject to all the terms and conditions of this Agreement as they are applicable to the Service;</li>
                <li>You will only use the Application in connection with an Apple device that you own or control;</li>
                <li>You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Application;</li>
                <li>In the event of any failure of the Application to conform to any applicable warranty, including those implied by law, you may notify Apple of such failure; upon notification, Apple's sole warranty obligation to you will be to refund to you the purchase price, if any, of the Application;</li>
                <li>You acknowledge and agree that CIPRA.ai, and not Apple, is responsible for addressing any claims you or any third party may have in relation to the Application;</li>
                <li>You acknowledge and agree that, in the event of any third-party claim that the Application or your possession and use of the Application infringes that third party's intellectual property rights, CIPRA.ai, and not Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement claim;</li>
                <li>You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country, and that you are not listed on any U.S. Government list of prohibited or restricted parties;</li>
                <li>Both you and CIPRA.ai acknowledge and agree that, in your use of the Application, you will comply with any applicable third-party terms of agreement which may affect or be affected by such use; and</li>
            </ol>
            <p>
                Both you and CIPRA.ai acknowledge and agree that Apple and Apple's subsidiaries are third-party beneficiaries of this Agreement, and that upon your acceptance of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as the third-party beneficiary hereof.
            </p>
            <li><b>General Terms</b></li>
            <p>
                <u>Use Restrictions</u>. You represent, warrant, and agree that you will not provide or contribute anything, including any Content (as that term is defined below), to the Service, or otherwise use or interact with the Service, in a manner that: (a) infringes or violates the intellectual property rights or any other rights of anyone else (including CIPRA.ai); (b) violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other purpose not reasonably intended by CIPRA.ai; (c) is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable; (d) jeopardizes the security of your User ID, account or anyone else's (such as allowing someone else to log in to the Service as you); (e) attempts, in any manner, to obtain the password, account, or other security information from any other user; (f) violates the security of any computer network, or cracks any passwords or security encryption codes; (g) runs Maillist, Listserv, any form of auto-responder or “spam” on the Service, or any processes that run or are activated while you are not logged into the Service, or that otherwise interfere with the proper working of the Service (including by placing an unreasonable load on the Service's infrastructure); (h) “crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Service or Content (through use of manual or automated means); (i) copies or stores any significant portion of the Content; or (j) decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Service. A violation of any of the foregoing is grounds for termination of your right to use or access the Service.
                <br></br>
                    <u>Warranty Disclaimer</u>. CIPRA.ai and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of their respective officers, directors, members, employees, consultants, contract employees, representatives and agents, and each of their respective successors and assigns (CIPRA.ai and all such parties together, the “CIPRA.ai Parties”) make no representations or warranties concerning the Service, including without limitation regarding any Content contained in or accessed through the Service, and the CIPRA.ai Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Service or any claims, actions, suits procedures, costs, expenses, damages or liabilities arising out of use of, or in any way related to your participation in, the Service. The CIPRA.ai Parties make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through or in connection with the Service. THE SERVICE AND CONTENT ARE PROVIDED BY CIPRA.AI (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                    <br></br>
                        <u>Limitation of Liability</u>. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE CIPRA.AI PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF ONE-HUNDRED ($100) DOLLARS OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
                        <br></br>
                            <u>Indemnity</u>. To the fullest extent allowed by applicable law, you agree to indemnify and hold the CIPRA.ai Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any claims relating to (a) your use of the Service (including any actions taken by a third party using your account), and (b) your violation of this Agreement. In the event of such a claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).
                            <br></br>
                                <u>Assignment</u>. You may not assign, delegate or transfer this Agreement or your rights or obligations hereunder, or your Service account, in any way (by operation of law or otherwise) without CIPRA.ai's prior written consent. We may transfer, assign, or delegate this Agreement and our rights and obligations without consent.
                                <br></br>
                                    <u>Choice of Law</u>. This Agreement is governed by and will be construed under the Federal Arbitration Act, applicable federal law, and the laws of the State of California, without regard to the conflicts of laws provisions thereof.
                                    <br></br>
                                        <u>Arbitration Agreement</u>. Please read the following ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain disputes and claims with CIPRA.ai and limits the manner in which you can seek relief from CIPRA.ai. Both you and CIPRA.ai acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject matter of this Agreement, CIPRA.ai's officers, directors, employees and independent contractors (“Personnel”) are third-party beneficiaries of this Agreement, and that upon your acceptance of this Agreement, Personnel will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as the third-party beneficiary hereof.
            </p>
            <ol type="a">
                <li><i>Arbitration Rules; Applicability of Arbitration Agreement</i>. The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of this Agreement directly through good-faith negotiations, which shall be a precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration in San Diego County, California. The arbitration will proceed in the English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with substantial experience in resolving commercial contract disputes. The arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction. </li>
                <li><i>Costs of Arbitration</i>. The Rules will govern payment of all arbitration fees. CIPRA.ai will pay all arbitration fees for claims less than seventy-five thousand ($75,000) dollars. CIPRA.ai will not seek its attorneys' fees and costs in arbitration unless the arbitrator determines that your claim is frivolous. </li>
                <li><i>Small Claims Court; Infringement</i>. Either you or CIPRA.ai may assert claims, if they qualify, in small claims court in San Diego County, California or any United States county where you live or work. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents or other intellectual property rights.</li>
                <li><i>Waiver of Jury Trial</i>. YOU AND CIPRA.AI WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and CIPRA.ai are instead choosing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. In any litigation between you and CIPRA.ai over whether to vacate or enforce an arbitration award, YOU AND CIPRA.AI WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.</li>
                <li><i>Waiver of Class or Consolidated Actions</i>. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor CIPRA.ai is entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth in (g) below. </li>
                <li><i>Opt-out</i>. You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to the following address: 4458 Philbrook Square, San Diego, CA 92130, postmarked within thirty (30) days of first accepting this Agreement. You must include (i) your name and residence address, (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that you want to opt out of this Agreement's arbitration agreement.</li>
                <li><i>Exclusive Venue</i>. If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration agreement permits either you or CIPRA.ai to litigate any dispute arising out of or relating to the subject matter of this Agreement in court, then the foregoing arbitration agreement will not apply to either party, and both you and CIPRA.ai agree that any judicial proceeding (other than small claims actions) will be brought in the state or federal courts located in, respectively, San Diego County, California, or the federal district in which that county falls. </li>
                <li><i>Severability</i>. If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration Agreement section will be null and void. This arbitration agreement will survive the termination of your relationship with CIPRA.ai.</li>
            </ol>
            <p>
                <i><u>Miscellaneous</u></i>. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that this Agreement shall otherwise remain in full force and effect and enforceable. If you have not signed a separate written agreement with CIPRA.ai related to the Service, you and CIPRA.ai agree that this Agreement is the complete and exclusive statement of the mutual understanding between you and CIPRA.ai, and that this Agreement supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this Agreement. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of CIPRA.ai, and you do not have any authority of any kind to bind CIPRA.ai in any respect whatsoever.
            </p>
            <p>
                Except as expressly set forth in the sections above regarding the Apple Application and the arbitration agreement, you and CIPRA.ai agree there are no third-party beneficiaries intended under this Agreement.
            </p>
        </ol>
</div>);
}
export default Terms;