import { useEffect, useState } from "react";
import { NewAccountPageTrigger } from '../../data/PageTrigger'
import { useParams } from "react-router";
import { PagesEnum } from "../../data/PageTrigger";
import { ENDPOINT, FormData } from "../../data/FormData";
import {support_contact} from "../../res/support"
const support: JSX.Element = support_contact
export function RegistartionPage(): JSX.Element {
    const { token } = useParams()
    const [showPage, setShowPage] = useState<PagesEnum>(PagesEnum.First);
    const [showPageContent, setShowPageContent] = useState<JSX.Element>(<div />);

    useEffect(() => {
        
        fetch(`${ENDPOINT}/validregistrationtoken/v2?token=${token}`)
            .then(response => {
                return response.json();
            }).then(data => {
                // Call the provided callback with the parsed JSON content
                console.log('JSON Data:', data);
                if (data.valid_token) {
                    // token is valid
                    FormData.instance.token = token!
                    //provider info
                    FormData.instance.provider = data["provider_info"]["provider"] ?? 'Other'
                    FormData.instance.provideDevice = data["provider_info"]["provide_device"]
                    FormData.instance.provideBpCuff = data["provider_info"]["provide_bp_cuff"]
                    FormData.instance.provideGlucometer = data["provider_info"]["provide_glucometer"]
                    FormData.instance.optOutWearableOption = data["provider_info"]["opt_out_wearable_option"];
                    FormData.instance.defaultBpCuff = data["provider_info"]["default_bp_cuff"];
                    FormData.instance.defaultGlucometer = data["provider_info"]["default_glucometer"];
                    FormData.instance.supportEmail = data["provider_info"]["support_email"]
                    FormData.instance.supportCell = data["provider_info"]["support_cell"]
                    FormData.instance.providerName = data["provider_info"]["name"]
                    console.log(FormData.instance.providerName = data["provider_info"]["name"])
                    console.log(FormData.instance.supportCell = data["provider_info"]["support_cell"])
                    console.log(FormData.instance.supportEmail = data["provider_info"]["support_email"])
                    //bp_cuff_bluetooth_required
                    FormData.instance.bpCuffBluetoothRequired = data["provider_info"]["bp_cuff_bluetooth_required"]
                    // program info
                    FormData.instance.program = data["program_info"]["program"] ?? ''
                    FormData.instance.showDevice = data["program_info"]["device"]
                    FormData.instance.showBpCuff = data["program_info"]["bp_cuff"]
                    FormData.instance.showGlucometer = data["program_info"]["glucometer"]
                    FormData.instance.programName = data["program_info"]["name"]
                    //email
                    FormData.instance.email = data["email"]
                    FormData.instance.subscriptionMonths = data["subscription_months"]
                    FormData.instance.languageId = data["language_id"]
                    NewAccountPageTrigger.instance.setNewAccountShowPage = (page: PagesEnum): void => {
                        setShowPage(page)
                    }
                    setShowPageContent(NewAccountPageTrigger.instance.getShowPage(showPage))
                } else {
                    const invalidPage: JSX.Element = <div style={{ marginTop: '30px' }}>
                        <p>This is not a valid registration link. </p>
                        {support} 
                    </div>
                    setShowPageContent(invalidPage)
                    return
                }

            })
            .catch(err => {
                console.log(err)
            })
            
    }, [token, showPage])

    return (
        <div className="App center-screen">
            {showPageContent}
        </div>
    );
}
export default RegistartionPage