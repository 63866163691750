import {ENDPOINT} from "./FormData";
export class AuthData{
    static instance: AuthData = new AuthData()
    authAPI = `${ENDPOINT}/auth/generateregistrationurl`
    userAuth(
        emailAddress: string,
        password:string,
        whenValid: (data:any) => void,
        whenNotValid: () => void
    ): void {
        const url = this.authAPI + "?password=" + password + "&email=" + emailAddress;
    fetch(url)
    .then(response => {
        if (response.status === 200) {
            return response.json();
        } else {
            // If response status is not 200, call whenNotValid()
            whenNotValid();
            throw new Error("Authentication failed"); // Throw error to skip further processing
        }
    })
    .then(data => {
        // Call the provided callback with the parsed JSON content
        whenValid(data);
        
    })
    .catch(err => {
        console.log(err);
    });
    }


}