import {CheckboxItem} from "../components/CheckboxGroup";
// import axios from "axios";

export const DEBUG_MODE = false

export const ENDPOINT = 'https://api.cipra.ai:5000'

export const initPreexistingConditions: CheckboxItem[] = [
    {key: 'Diabetes', text: 'Diabetes', checked: false},
    {key: 'Hypertension', text: 'Hypertension', checked: false},
    {key: 'LiverDisease', text: 'Liver Disease', checked: false},
    {key: 'LungDisease', text: 'Lung Disease', checked: false},
    {key: 'SleepApnea', text: 'Sleep Apnea', checked: false},
    {key: 'NoneOfAbove', text: 'None of above', checked: false}
]

export const initRaceList: CheckboxItem[] = [
    {key: 'American Indian or Alaskan Native', text: 'American Indian or Alaskan Native', checked: false},
    {key: 'Asian / Pacific Islander', text: 'Asian / Pacific Islander', checked: false},
    {key: 'Black or African American', text: 'Black or African American', checked: false},
    {key: 'Hispanic', text: 'Hispanic', checked: false},
    {key: 'White / Caucasian', text: 'White / Caucasian', checked: false},
    {key: 'Other', text: 'Other (please specify)', checked: false},
]

function getRaceList(raceList: CheckboxItem[], otherRace: string): string[] {
    const list = raceList.filter(item => item.checked).map(item => item.key)
    return list.map(val => {
        if(val === "Other") return otherRace;
        return val;
    })
}

export class FormData {

    static instance: FormData = new FormData();
    token:string = '';
    email: string = '';
    cell: string = '';
    password: string = '';

    provider: string = '';
    program: string = '';
    firstName: string = '';
    lastName: string = '';
    gender: string = '';
    age: string = '';
    heightFeet: string = '';
    heightInches: string = '';
    weight: string = '';
    zipCode: string = '';
    preexistingCondition: CheckboxItem[] = initPreexistingConditions;
    race: CheckboxItem[] = initRaceList
    otherRace: string = ""
    supportEmail: string = ""
    supportCell: string = ""
    programName: string = ""
    providerName: string = ""

    bloodPressureMedication: string = '';
    dmMedication: string = '';
    phoneType: 'Apple' | 'Android' | '' = '';
    deviceType: string = '';
    bloodPressureMonitor: string = '';
    bpCuffBluetoothRequired: boolean = false;
    glucometer: string = '';
    address: string = '';
    addressCity: string = '';
    addressState: string = '';
    addressZipcode: string = '';
    subscriptionMonths:string = '';
    languageId:string = '';


    getFullAddress(): string {
        const fullAddress = `${this.address}\n${this.addressCity}, ${this.addressState} ${this.addressZipcode}`
        return fullAddress === '\n,  ' ? '' : fullAddress
    }

    // control
    provideDevice: boolean = false;
    provideBpCuff: boolean = false;
    provideGlucometer: boolean = false;
    optOutWearableOption: boolean  = false;
    defaultBpCuff: string | null = null;
    defaultGlucometer:string | null = null;
    showDevice: boolean = false;
    showBpCuff: boolean = false;
    showGlucometer: boolean = false;

    submit(callbackSuccess: () => void, callbackFail: () => void): void {
        const submitData = {
            token: this.token,
            provider: this.provider,
            program: this.program,
            first_name: this.firstName,
            last_name: this.lastName,
            password: this.password,
            cell: '+' + this.cell,
            email: this.email,
            htn_med: this.bloodPressureMedication,
            dm_med: this.dmMedication,
            os: this.phoneType,
            device: this.deviceType,
            bp_cuff: this.defaultBpCuff != null && (this.program.toUpperCase() === "HTN" || this.program.toUpperCase() === "HTN_IN") ? 
                                        this.defaultBpCuff : this.bloodPressureMonitor ,
            glucometer: this.defaultGlucometer != null &&(this.program.toUpperCase() === "DM" || this.program.toUpperCase() === "DM_IN") ? 
                                        this.defaultGlucometer : this.glucometer,
            age: this.age,
            gender: this.gender,
            race: getRaceList(this.race, this.otherRace).join(' || '),
            // if user doesn't enter a height value, then it should be an empty string
            height: (Number(this.heightFeet) === 0 && Number(this.heightInches) === 0) ? 
                    "" : String(Number(this.heightFeet) * 12 + Number(this.heightInches)),
            weight: this.weight,
            'zip code': this.zipCode,
            conditions: this.preexistingCondition.filter(item => item.checked).map(item => item.key).join(' || '),
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            address: this.getFullAddress(),
            subscription_months:this.subscriptionMonths,
            language_id:this.languageId
        }
        console.log(submitData)
        if(DEBUG_MODE) return
        fetch(`${ENDPOINT}/newaccount`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(submitData)
        })
            .then(response => {
                console.log(response)
                callbackSuccess()
            })
            .catch(e => {
                console.log('Network Issue: ' + e)
                callbackFail()
            })
        //axios.post(ENDPOINT, JSON.stringify(submitData))
        //    .then(callbackSuccess)
        //    .catch(callbackFail)
    }
}
