import {
    Button,
    Checkbox, Grid, Input,
    RadioGroup,
    SpaceBetween,
    Spinner,
    TextContent
} from "@cloudscape-design/components";

import { useState } from "react";
import { DEBUG_MODE, FormData } from "../../data/FormData";
import { NewAccountPageTrigger, PagesEnum } from "../../data/PageTrigger";
import cipraLogo from '../../res/cipra_logo.jpg'
import phsoLogo from "../../res/phso-logo.png"
import cdxLogo from "../../res/cdx-logo.png"
import h2050Logo from "../../res/h2050-logo.jpg"
import nhcLogo from "../../res/nhc-logo.jpg"
import rxdxLogo from "../../res/rxdx-logo.png"
import cabLogo from '../../res/cab_logo.png'
export function QuestionnaireSimplePage(): JSX.Element {
    // const [success, setSuccess] = useState<boolean>(false)
    // // const [provider, setProvider] = useState(FormData.instance.provider)
    // const [firstName, setFirstName] = useState(FormData.instance.firstName)
    // const [lastName, setLastName] = useState(FormData.instance.lastName)
    // const [takeBloodPressureMedicationValue, setTakeBloodPressureMedicationValue] = useState<string>(FormData.instance.bloodPressureMedication)
    // const [takeDiabetesMedicationValue, setTakeDiabetesMedicationValue] = useState<string>(FormData.instance.dmMedication)

    // const [phoneModel, setPhoneModel] = useState<'Apple' | 'Android' | ''>(FormData.instance.phoneType)

    // const [selectAppleWatchModel, setSelectAppleWatchModel] = useState(FormData.instance.deviceType === 'Apple Watch')
    // const [selectFitbitModel, setSelectFitbitModel] = useState(FormData.instance.deviceType === 'Fitbit')
    // const [selectNo, setSelectNo] = useState(FormData.instance.deviceType === 'No')
    // const [selectOpt, setSelectOpt] = useState(FormData.instance.deviceType === 'opt-out')

    // const [deviceModel, setDeviceModel] = useState<string>(FormData.instance.deviceModel)

    // const [bloodPressureMonitor, setBloodPressureMonitor] = useState<string>(FormData.instance.bloodPressureMonitor)
    // const [glucometer, setGlucometer] = useState<string>(FormData.instance.glucometer)

    // const [address, setAddress] = useState<string>(FormData.instance.address)
    // const [addressCity, setAddressCity] = useState<string>(FormData.instance.addressCity)
    // const [addressState, setAddressState] = useState<string>(FormData.instance.addressState)
    // const [addressZipcode, setAddressZipcode] = useState<string>(FormData.instance.addressZipcode)

    // const [submitWaiting, setSubmitWaiting] = useState<boolean>(false)
    // const [submitFailed, setSubmitFailed] = useState<boolean>(false)
    // const [firstLoaded, setFirstLoaded] = useState<boolean>(true)

    // function gotoPage(page: PagesEnum) {
    //     if (NewAccountPageTrigger.instance.setNewAccountShowPage) {
    //         NewAccountPageTrigger.instance.setNewAccountShowPage(page)
    //     }
    // }

    // const renderProviderLogo = () => {
    //     const provider = FormData.instance.provider.toUpperCase();
    //     if (provider === "CDX") {
    //         return <img src={cdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
    //     } else if (provider === "H2050") {
    //         return <img src={h2050Logo} alt='provider logo' width='90%' className='line-align-center' />;
    //     } else if (provider === "NHC") {
    //         return <img src={nhcLogo} alt='provider logo' width='90%' className='line-align-center' />;
    //     } else if (provider === "PHSO") {
    //         return <img src={phsoLogo} alt='provider logo' width='90%' className='line-align-center' />;
    //     } else if (provider === "RXDX") {
    //         return <img src={rxdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
    //     } else if (provider === "CAB"){
    //         return <img src={cabLogo} alt='provider logo' width='90%' className='line-align-center' />;
    //     } else {
    //         return <img src={cipraLogo} alt='provider logo' width='90%' className='line-align-center' />;
    //     }
    // };
    // const poweredBy = () => {
    //     const provider = FormData.instance.provider.toUpperCase();
    //     if (provider === "CDX" || provider === "H2050" || provider === "NHC" || provider === "PHSO" || provider === "RXDX"|| provider == "CAB") {
    //         return (
    //             <div className='line-align-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //                 <p style={{ marginRight: '10px' }}>Enabled by:</p>
    //                 <img src={cipraLogo} alt="Description" style={{ width: 'auto', maxWidth: '100%', height: '30px' }} />
    //             </div>
    //         );
    //     } else {
    //         return null;
    //     }
    // }

    // function submit() {
    //     const whenSuccess = (): void => {
    //         setSuccess(true)
    //     }
    //     const whenFail = (): void => {
    //         setSubmitWaiting(false)
    //         setSubmitFailed(true)
    //     }
    //     if (DEBUG_MODE) {
    //         FormData.instance.submit(whenSuccess, whenFail)
    //     }
    //     setFirstLoaded(false)
    //     if (firstName.length == 0 || lastName.length == 0){
    //         console.log("first name or last name empty")
    //         return
    //     }
    //     if (FormData.instance.showBpCuff && takeBloodPressureMedicationValue.length === 0) {
    //         console.log("takeBloodPressureMedicationValue empty")
    //         return
    //     }
    //     if (FormData.instance.showGlucometer && takeDiabetesMedicationValue.length === 0) {
    //         console.log("takeDiabetesMedicationValue empty")
    //         return
    //     }
    //     if (phoneModel.length === 0) {
    //         console.log("phone model empty")
    //         return
    //     }
    //     if (FormData.instance.showDevice && (selectAppleWatchModel || selectFitbitModel) && deviceModel.length === 0) {
    //         console.log("devide Model is empty")
    //         return
    //     }
    //     // make sure device is not empty
    //     if (FormData.instance.showDevice && FormData.instance.deviceType.length === 0) {
    //         console.log("please select your device")
    //         return
    //     }
    //     if (bloodPressureMonitor === 'No' || selectNo || glucometer === 'No') {
    //         var flag = false
    //         if (address.length === 0) {
    //             console.log("Please input your address")
    //             flag = true
    //         }
    //         if (addressCity.length === 0) {
    //             console.log("Please input your address city")
    //             flag = true
    //         }
    //         if (addressState.length === 0) {
    //             console.log("Please input your address state")
    //             flag = true
    //         }
    //         if (addressZipcode.length === 0) {
    //             console.log("Please input your address state")
    //             flag = true
    //         }
    //         if (flag) {
    //             return
    //         }

    //     }
    //     if (FormData.instance.showBpCuff && bloodPressureMonitor.length === 0) {
    //         if (FormData.instance.defaultBpCuff == null) {
    //             console.log("bloodPressureMonitor is empty")
    //             return
    //         }
    //     }
    //     if (FormData.instance.showGlucometer && glucometer.length === 0) {
    //         if (FormData.instance.defaultGlucometer == null) {
    //             return
    //         }
    //     }
    //     setSubmitWaiting(true)
    //     FormData.instance.submit(whenSuccess, whenFail)
    // }

    // Questions from page 2
    // Are you taking blood pressure medication?
    // const contentHtnMed: JSX.Element = <SpaceBetween size='s' direction='vertical'>
    //     <SpaceBetween size='m' direction='horizontal'>
    //         <div>Are you taking blood pressure medication?</div>
    //     </SpaceBetween>
    //     <RadioGroup
    //         onChange={({ detail }) => {
    //             setTakeBloodPressureMedicationValue(detail.value)
    //             FormData.instance.bloodPressureMedication = detail.value
    //         }}
    //         value={takeBloodPressureMedicationValue}
    //         items={[
    //             { value: "1", label: "Yes" },
    //             { value: "0", label: "No" }
    //         ]}
    //     />
    //     {
    //         !(firstLoaded || takeBloodPressureMedicationValue.length > 0) && <p className='tip-text'>Please select Yes or No.</p>
    //     }
    // </SpaceBetween>
    // // Are you taking diabetes medication?
    // const contentDmMed: JSX.Element = <SpaceBetween size='s' direction='vertical'>
    //     <SpaceBetween size='m' direction='horizontal'>
    //         <div>Are you taking diabetes medication?</div>
    //     </SpaceBetween>
    //     <RadioGroup
    //         onChange={({ detail }) => {
    //             setTakeDiabetesMedicationValue(detail.value)
    //             FormData.instance.dmMedication = detail.value
    //         }}
    //         value={takeDiabetesMedicationValue}
    //         items={[
    //             { value: "1", label: "Yes" },
    //             { value: "0", label: "No" }
    //         ]}
    //     />
    //     {
    //         !(firstLoaded || takeDiabetesMedicationValue.length > 0) && <p className='tip-text'>Please select Yes or No.</p>
    //     }
    // </SpaceBetween>
    // // Do you have an Apple or Android phone?
    // const contentPhone: JSX.Element = <SpaceBetween size='s' direction='vertical'>
    //     <SpaceBetween size='m' direction='horizontal'>
    //         <div>Do you have an Apple or Android phone?</div>
    //     </SpaceBetween>
    //     <RadioGroup value={phoneModel}
    //         onChange={({ detail }) => {
    //             setPhoneModel(detail.value as 'Apple' | 'Android')
    //             FormData.instance.phoneType = detail.value as 'Apple' | 'Android'
    //             setSelectAppleWatchModel(false)
    //             FormData.instance.deviceType = ''
    //         }}
    //         items={[
    //             { value: 'Apple', label: 'Apple' },
    //             { value: 'Android', label: 'Android' }
    //         ]}
    //     />
    //     {
    //         !(firstLoaded || phoneModel.length > 0) && <p className='tip-text'>Please select your phone model.</p>
    //     }
    // </SpaceBetween>
    // // Do you own and actively use an Apple Watch or Fitbit?
    // const contentDevice: JSX.Element = <SpaceBetween size='s' direction='vertical'>
    //     <SpaceBetween size='m' direction='horizontal'>
    //         <div>Do you own or will you get an Apple Watch or Fitbit?</div>
    //     </SpaceBetween>
    //     {
    //         (phoneModel === 'Apple' || phoneModel === '') &&
    //         <Checkbox checked={selectAppleWatchModel} onChange={({ detail }) => {
    //             setSelectAppleWatchModel(detail.checked)
    //             setDeviceModel('')
    //             setSelectFitbitModel(false)
    //             setSelectNo(false)
    //             setSelectOpt(false)
    //             if (detail.checked) {
    //                 FormData.instance.deviceType = 'Apple Watch'
    //             } else {
    //                 FormData.instance.deviceType = ''
    //             }
    //         }}>Apple Watch</Checkbox>
    //     }
    //     {
    //         (phoneModel === 'Apple' || phoneModel === '') && selectAppleWatchModel &&
    //         <SpaceBetween size='s' direction='vertical' className='item-indent'>
    //             <SpaceBetween size='m' direction='horizontal'>
    //                 <div>What is your Apple Watch model?</div>
    //             </SpaceBetween>
    //             <RadioGroup value={deviceModel}
    //                 onChange={({ detail }) => {
    //                     setDeviceModel(detail.value)
    //                     FormData.instance.deviceModel = detail.value
    //                 }}
    //                 items={[
    //                     { value: 'Ultra', label: 'Ultra' },
    //                     { value: 'Series 8', label: 'Series 8' },
    //                     { value: 'Series 7', label: 'Series 7' },
    //                     { value: 'Series 6', label: 'Series 6' },
    //                     { value: 'Series SE', label: 'Series SE' },
    //                     { value: 'Series 5', label: 'Series 5' },
    //                     { value: 'Series 4', label: 'Series 4' },
    //                     { value: 'Series 3 or earlier', label: 'Series 3 or earlier' }
    //                 ]}
    //             />
    //         </SpaceBetween>
    //     }
    //     <Checkbox checked={selectFitbitModel} onChange={({ detail }) => {
    //         setSelectFitbitModel(detail.checked)
    //         setDeviceModel('')
    //         setSelectNo(false)
    //         setSelectOpt(false)
    //         setSelectAppleWatchModel(false)
    //         if (detail.checked) {
    //             FormData.instance.deviceType = 'Fitbit'
    //         } else {
    //             setSelectFitbitModel(false)
    //             FormData.instance.deviceType = ''
    //         }
    //     }}>Fitbit</Checkbox>
    //     {
    //         selectFitbitModel &&
    //         <SpaceBetween size='s' direction='vertical' className='item-indent'>
    //             <SpaceBetween size='m' direction='horizontal'>
    //                 <div>What is your Fitbit model?</div>
    //             </SpaceBetween>
    //             <RadioGroup value={deviceModel}
    //                 onChange={({ detail }) => {
    //                     setDeviceModel(detail.value)
    //                     FormData.instance.deviceModel = detail.value
    //                 }}
    //                 items={[
    //                     { value: 'Inspire 2', label: 'Inspire 2' },
    //                     { value: 'Inspire 3', label: 'Inspire 3' },
    //                     { value: 'Charge 4', label: 'Charge 4' },
    //                     { value: 'Charge 5', label: 'Charge 5' },
    //                     { value: 'Sense', label: 'Sense' },
    //                     { value: 'Sense 2', label: 'Sense 2' },
    //                     { value: 'Versa 2', label: 'Versa 2' },
    //                     { value: 'Versa 3', label: 'Versa 3' },
    //                     { value: 'Versa 4', label: 'Versa 4' },
    //                     { value: 'Luxe', label: 'Luxe' },
    //                     { value: 'Other', label: 'Other' }
    //                 ]}
    //             />
    //         </SpaceBetween>
    //     }
    //     {FormData.instance.provideDevice &&
    //         <Checkbox checked={selectNo} onChange={({ detail }) => {
    //             setSelectNo(detail.checked)
    //             setDeviceModel('')
    //             FormData.instance.deviceModel = ''
    //             setSelectOpt(false)
    //             setSelectAppleWatchModel(false)
    //             setSelectFitbitModel(false)
    //             if (detail.checked) {
    //                 FormData.instance.deviceType = 'No'
    //                 setDeviceModel('No')
    //             } else {
    //                 FormData.instance.deviceType = ''
    //             }
    //         }}>No, I need to be sent a device.</Checkbox>
    //     }
    //     {FormData.instance.optOutWearableOption &&
    //         <Checkbox checked={selectOpt} onChange={({ detail }) => {
    //             setSelectOpt(detail.checked)
    //             setSelectNo(false)
    //             FormData.instance.deviceModel = ''
    //             setDeviceModel('')
    //             setSelectAppleWatchModel(false)
    //             setSelectFitbitModel(false)
    //             if (detail.checked) {
    //                 FormData.instance.deviceType = 'opt-out'
    //                 setDeviceModel('opt-out')
    //             } else {
    //                 FormData.instance.deviceType = ''
    //             }
    //         }}>I would prefer not to wear a device for the program</Checkbox>
    //     }
    //     {
    //         !(firstLoaded || deviceModel.length > 0) && <p className='tip-text'>Please select your device model.</p>
    //     }
    //     {
    //         !(firstLoaded || FormData.instance.deviceType.length > 0) && <p className='tip-text'>Please select your device type.</p>
    //     }
    // </SpaceBetween>
    // // Do you own a Bluetooth-enabled Omron or iHealth blood pressure monitor?
    // const contentBpCuff: JSX.Element = <SpaceBetween size='s' direction='vertical'>
    //     <SpaceBetween size='m' direction='horizontal'>
    //         <div>Do you own a Bluetooth-enabled Omron or iHealth blood pressure monitor?</div>
    //     </SpaceBetween>
    //     <RadioGroup value={bloodPressureMonitor}
    //         onChange={({ detail }) => {
    //             setBloodPressureMonitor(detail.value)
    //             FormData.instance.bloodPressureMonitor = detail.value
    //         }}
    //         items={
    //             FormData.instance.provideBpCuff ?
    //                 [
    //                     { value: 'Omron', label: 'Omron' },
    //                     { value: 'iHealth', label: 'iHealth' },
    //                     { value: 'No', label: 'No, I need to be sent a device' },
    //                     // bp_cuff_bluetooth_required == false -  "Manual"
    //                     ...(!FormData.instance.bpCuffBluetoothRequired ? [{ value: 'Manual', label: 'Other (non-Bluetooth monitor)' }] : [])
    //                 ] : [
    //                     { value: 'Omron', label: 'Omron' },
    //                     { value: 'iHealth', label: 'iHealth' },
    //                    // bp_cuff_bluetooth_required == false -  "Manual"
    //                     ...(!FormData.instance.bpCuffBluetoothRequired ? [{ value: 'Manual', label: 'Other (non-Bluetooth monitor)' }] : [])
    //                 ]
    //         } />
    //     {
    //         !(firstLoaded || bloodPressureMonitor.length > 0) && <p className='tip-text'>Please select your blood pressure monitor.</p>
    //     }
    // </SpaceBetween>
    // // Do you own a glucose meter?
    // const contentGlucometer: JSX.Element = <SpaceBetween size='s' direction='vertical'>
    //     <SpaceBetween size='m' direction='horizontal'>
    //         <div>Do you own a glucose meter?</div>
    //     </SpaceBetween>
    //     <RadioGroup value={glucometer}
    //         onChange={({ detail }) => {
    //             setGlucometer(detail.value)
    //             FormData.instance.glucometer = detail.value
    //         }}
    //         items={
    //             FormData.instance.provideGlucometer ?
    //                 [
    //                     { value: 'FreeStyle Libre', label: 'FreeStyle Libre' },
    //                     { value: 'Glooko', label: 'Glooko' },
    //                     { value: 'Other', label: 'Other' },
    //                     { value: 'No', label: 'No' }
    //                 ] : [
    //                     { value: 'FreeStyle Libre', label: 'FreeStyle Libre' },
    //                     { value: 'Glooko', label: 'Glooko' },
    //                     { value: 'Other', label: 'Other' }
    //                 ]
    //         } />
    //     {
    //         !(firstLoaded || glucometer.length > 0) && <p className='tip-text'>Please select your glucose meter.</p>
    //     }
    // </SpaceBetween>
    // // Please enter your mailing address so that we can ship you the device(s)
    // const contentAddress: JSX.Element = <SpaceBetween size='s' direction='vertical'>
    //     <SpaceBetween size='m' direction='horizontal'>
    //         <div>Please enter your mailing address so that we can ship you the device(s)</div>
    //     </SpaceBetween>
    //     <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
    //         <TextContent>
    //             <p>Address</p>
    //         </TextContent>
    //         <Input value={address} onChange={({ detail }) => {
    //             setAddress(detail.value)
    //             FormData.instance.address = detail.value
    //         }} placeholder='Address' />
    //     </Grid>
    //     {
    //         !(firstLoaded || address.length > 0) && <p className='tip-text'>Please enter your address.</p>
    //     }
    //     <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
    //         <TextContent>
    //             <p>City</p>
    //         </TextContent>
    //         <Input value={addressCity} onChange={({ detail }) => {
    //             setAddressCity(detail.value)
    //             FormData.instance.addressCity = detail.value
    //         }} placeholder='City' />
    //     </Grid>
    //     {
    //         !(firstLoaded || addressCity.length > 0) && <p className='tip-text'>Please enter your city.</p>
    //     }
    //     <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
    //         <TextContent>
    //             <p>State</p>
    //         </TextContent>
    //         <Input value={addressState} onChange={({ detail }) => {
    //             setAddressState(detail.value)
    //             FormData.instance.addressState = detail.value
    //         }} placeholder='State' />
    //     </Grid>
    //     {
    //         !(firstLoaded || addressState.length > 0) && <p className='tip-text'>Please enter your state.</p>
    //     }
    //     <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
    //         <TextContent>
    //             <p>Zip</p>
    //         </TextContent>
    //         <Input value={addressZipcode} onChange={({ detail }) => {
    //             setAddressZipcode(detail.value)
    //             FormData.instance.addressZipcode = detail.value
    //         }} placeholder='Zip code' />
    //     </Grid>
    //     {
    //         !(firstLoaded || addressZipcode.length > 0) && <p className='tip-text'>Please enter your zipcode.</p>
    //     }
    // </SpaceBetween>

    return <div>
    </div>


}
