import {CreateAccountEntryPage} from "../pages/new-account/CreateAccountEntryPage";
import {QuestionnairePage1} from "../pages/new-account/QuestionnairePage1";
import {QuestionnairePage2} from "../pages/new-account/QuestionnairePage2";
import {QuestionnaireSimplePage} from "../pages/new-account/QuestionnaireSimplePage";
import {CreateAccountSuccessPage} from "../pages/new-account/CreateAccountSuccessPage";
import React from "react";

export enum PagesEnum {
  First,
  Second,
  Third,
  Simple,
  Success
}

export class NewAccountPageTrigger {
  static instance: NewAccountPageTrigger = new NewAccountPageTrigger()
  setNewAccountShowPage: ((page: PagesEnum) => void) | undefined = undefined;
  getShowPage = (pageEnum: PagesEnum): JSX.Element => {
    if(this.setNewAccountShowPage === undefined) <div></div>
    switch (pageEnum){
      case PagesEnum.First:
        return <CreateAccountEntryPage></CreateAccountEntryPage>
      case PagesEnum.Second:
        return <QuestionnairePage1></QuestionnairePage1>
      case PagesEnum.Third:
        return <QuestionnairePage2></QuestionnairePage2>
      case PagesEnum.Simple:
        return <QuestionnaireSimplePage></QuestionnaireSimplePage>
      case PagesEnum.Success:
        return <CreateAccountSuccessPage></CreateAccountSuccessPage>
    }
    return <div></div>
  }
}
