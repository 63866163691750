import { ENDPOINT } from "./FormData";

export class UnsubscribeData{
    static instance: UnsubscribeData = new UnsubscribeData()

    EndpointEmailExist = `${ENDPOINT}/emailexists/v2/`
    EndpointSendEmail = `${ENDPOINT}/sendunsubscribeurl?` // parameter email 
    //(https://api.cipra.ai:5000/sendunsubscribeurl?email=jared.leitner@gmail.com&patientID=9999)

    emailExistv2(
        emailAddress: string,
        whenExist: (data:any) => void,
        whenNotExist: () => void
    ): void {
        const url = this.EndpointEmailExist + emailAddress;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                // Call the provided callback with the parsed JSON content
                if (data.email_exists) {
                    whenExist(data);
                } else {
                    whenNotExist();
                }
                
            })
            .catch(err => {
                console.log(err)
            })
    }
    sendEmail(
        emailAddress: string,
        patientID:string,
        autoUnsubParam:string,
        whenSuccess: (data:any) => void,
        whenFailed: () => void
    ): void {
        var url;
        if(autoUnsubParam ===  ""){
            url = this.EndpointSendEmail + 'email='+emailAddress + '&patientID=' + patientID;
        }else{
            url = this.EndpointSendEmail + 'email='+emailAddress + '&patientID=' + patientID + "&auto_unsub=" + autoUnsubParam;
        }
        console.log(url)
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if(response.ok){
                    return response.text()
                } else {
                    whenFailed()
                }
            })
            .then(data =>{
                console.log(data);
                whenSuccess(data);
            })
            .catch(err => {
                console.log(err);
                whenFailed();
            })
    }
}