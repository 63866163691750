export const support_contact: JSX.Element = <div>
    <p>Please contact our support for help.</p>
    <div style={{ marginTop: '40px', marginBottom: '40px'}}>
    <b>US Support</b>
    <p>Email: <a href='mailto: support@cipra.ai'>support@cipra.ai</a></p>
    <p>Phone: (619)500-3680</p>
    </div>
    <div>
    <b>India Support</b>
    <p>Email: <a href='mailto: support.in@cipra.ai'>support.in@cipra.ai</a></p>
    <p>Phone: +91 9008791231</p>
    </div>
    
</div>