import {
    Button,
    Grid, Input,
    RadioGroup,
    SpaceBetween,
    Spinner,
    TextContent
} from "@cloudscape-design/components";
import { useState } from "react";
import { DEBUG_MODE, FormData } from "../../data/FormData";
import cipraLogo from '../../res/cipra_logo.jpg'
import phsoLogo from "../../res/phso-logo.png"
import cdxLogo from "../../res/cdx-logo.png"
import h2050Logo from "../../res/h2050-logo.jpg"
import nhcLogo from "../../res/nhc-logo.jpg"
import rxdxLogo from "../../res/rxdx-logo.png"
import cabLogo from '../../res/cab_logo.png'
import { NewAccountPageTrigger, PagesEnum } from "../../data/PageTrigger";

export function QuestionnairePage2(): JSX.Element {
    const [success, setSuccess] = useState<boolean>(false)
    const [takeBloodPressureMedicationValue, setTakeBloodPressureMedicationValue] = useState<string>(FormData.instance.bloodPressureMedication)
    const [takeDiabetesMedicationValue, setTakeDiabetesMedicationValue] = useState<string>(FormData.instance.dmMedication)

    const [phoneModel, setPhoneModel] = useState<'Apple' | 'Android' | ''>(FormData.instance.phoneType)
    const [deviceType, setDeviceType] = useState<string>(FormData.instance.deviceType)

    const [bloodPressureMonitor, setBloodPressureMonitor] = useState<string>(FormData.instance.bloodPressureMonitor)
    const [glucometer, setGlucometer] = useState<string>(FormData.instance.glucometer)

    const [address, setAddress] = useState<string>(FormData.instance.address)
    const [addressCity, setAddressCity] = useState<string>(FormData.instance.addressCity)
    const [addressState, setAddressState] = useState<string>(FormData.instance.addressState)
    const [addressZipcode, setAddressZipcode] = useState<string>(FormData.instance.addressZipcode)

    const [firstLoaded, setFirstLoaded] = useState<boolean>(true)

    const [submitWaiting, setSubmitWaiting] = useState<boolean>(false)
    const [submitFailed, setSubmitFailed] = useState<boolean>(false)

    function gotoPage(page: PagesEnum) {
        if (NewAccountPageTrigger.instance.setNewAccountShowPage) {
            NewAccountPageTrigger.instance.setNewAccountShowPage(page)
        }
    }
    const renderProviderLogo = () => {
        const provider = FormData.instance.provider.toUpperCase();
        if (provider === "CDX") {
            return <img src={cdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "H2050") {
            return <img src={h2050Logo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "NHC") {
            return <img src={nhcLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "PHSO") {
            return <img src={phsoLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "RXDX") {
            return <img src={rxdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "CAB"){
            return <img src={cabLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else {
            return <img src={cipraLogo} alt='provider logo' width='90%' className='line-align-center' />;
        }
    };
    const poweredBy = () => {
        const provider = FormData.instance.provider.toUpperCase();
        if (provider === "CDX" || provider === "H2050" || provider === "NHC" || provider === "PHSO" || provider === "RXDX"|| provider == "CAB") {
            return (
                <div className='line-align-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ marginRight: '10px' }}>Enabled by:</p>
                    <img src={cipraLogo} alt="Description" style={{ width: 'auto', maxWidth: '100%', height: '30px' }} />
                </div>
            );
        } else {
            return null;
        }
    }
    function submit() {
        const whenSuccess = (): void => {
            setSuccess(true)
        }
        const whenFail = (): void => {
            setSubmitWaiting(false)
            setSubmitFailed(true)
        }
        if (DEBUG_MODE) {
            FormData.instance.submit(whenSuccess, whenFail)
        }
        setFirstLoaded(false)
        if (FormData.instance.showBpCuff && takeBloodPressureMedicationValue.length === 0) {
            console.log("takeBloodPressureMedicationValue empty")
            return
        }
        if (FormData.instance.showGlucometer && takeDiabetesMedicationValue.length === 0) {
            console.log("takeDiabetesMedicationValue empty")
            return
        }
        if (phoneModel.length === 0) {
            console.log("phone model empty")
            return
        }
        // make sure device is not empty
        if (FormData.instance.showDevice && FormData.instance.deviceType.length === 0) {
            console.log("please select your device")
            return
        }
        if (bloodPressureMonitor === 'No' || FormData.instance.deviceType == "no" || glucometer === 'No'){
            var flag = false
            if (address.length === 0){
                console.log("Please input your address")
                flag = true
            }
            if(addressCity.length === 0){
                console.log("Please input your address city")
                flag = true
            }
            if (addressState.length === 0){
                console.log("Please input your address state")
                flag = true
            }
            if (addressZipcode.length === 0){
                console.log("Please input your address state")
                flag = true
            }
            if (flag){
                return
            }
            
        }
        if (FormData.instance.showBpCuff && bloodPressureMonitor.length === 0) {
            if (FormData.instance.defaultBpCuff == null) {
                console.log("bloodPressureMonitor is empty")
                return
            }
        }
        if (FormData.instance.showGlucometer && glucometer.length === 0) {
            if (FormData.instance.defaultGlucometer == null) {
                return
            }
        }
        setSubmitWaiting(true)
        FormData.instance.submit(whenSuccess, whenFail)
    }

    // Are you taking blood pressure medication?
    const contentHtnMed: JSX.Element = <SpaceBetween size='s' direction='vertical'>
        <SpaceBetween size='m' direction='horizontal'>
            <div>Are you taking blood pressure medication?</div>
        </SpaceBetween>
        <RadioGroup
            onChange={({ detail }) => {
                setTakeBloodPressureMedicationValue(detail.value)
                FormData.instance.bloodPressureMedication = detail.value
            }}
            value={takeBloodPressureMedicationValue}
            items={[
                { value: "1", label: "Yes" },
                { value: "0", label: "No" }
            ]}
        />
        {
            !(firstLoaded || takeBloodPressureMedicationValue.length > 0) && <p className='tip-text'>Please select Yes or No.</p>
        }
    </SpaceBetween>
    // Are you taking diabetes medication?
    const contentDmMed: JSX.Element = <SpaceBetween size='s' direction='vertical'>
        <SpaceBetween size='m' direction='horizontal'>
            <div>Are you taking diabetes medication?</div>
        </SpaceBetween>
        <RadioGroup
            onChange={({ detail }) => {
                setTakeDiabetesMedicationValue(detail.value)
                FormData.instance.dmMedication = detail.value
            }}
            value={takeDiabetesMedicationValue}
            items={[
                { value: "1", label: "Yes" },
                { value: "0", label: "No" }
            ]}
        />
        {
            !(firstLoaded || takeDiabetesMedicationValue.length > 0) && <p className='tip-text'>Please select Yes or No.</p>
        }
    </SpaceBetween>
    // Do you have an Apple or Android phone?
    const contentPhone: JSX.Element = <SpaceBetween size='s' direction='vertical'>
        <SpaceBetween size='m' direction='horizontal'>
            <div>Do you have an Apple or Android phone?</div>
        </SpaceBetween>
        <RadioGroup value={phoneModel}
            onChange={({ detail }) => {
                setPhoneModel(detail.value as 'Apple' | 'Android')
                FormData.instance.phoneType = detail.value as 'Apple' | 'Android'
            }}
            items={[
                { value: 'Apple', label: 'Apple' },
                { value: 'Android', label: 'Android' }
            ]}
        />
        {
            !(firstLoaded || phoneModel.length > 0) && <p className='tip-text'>Please select your phone model.</p>
        }
    </SpaceBetween>
    // Do you own or will you get an Apple Watch or Fitbit?
    const contentDevice: JSX.Element = <SpaceBetween size='s' direction='vertical'>
        <SpaceBetween size='m' direction='horizontal'>
            <div>Do you have an Apple Watch or Fitbit that you will use for the program?</div>
        </SpaceBetween>
        <RadioGroup value={deviceType}
            onChange={({ detail }) => {
                FormData.instance.deviceType = detail.value
                setDeviceType(detail.value)
            }}
            items={[
                ...(FormData.instance.phoneType != "Android" ? [{ value: 'Apple Watch', label: 'Yes, I will use my Apple Watch' }] : []),
                { value: 'Fitbit', label: 'Yes, I will use my Fitbit' },
                ...(FormData.instance.provideDevice ? [{ value: 'No', label: 'No, please send me a Fitbit' }] : []),
                ...(FormData.instance.optOutWearableOption ? [{ value: 'opt-out', label: 'No, I prefer not to wear a device and will self-report my exercise and sleep data' }] : [])
            ]}
        />
        {
            !(firstLoaded || FormData.instance.deviceType.length > 0) && <p className='tip-text'>Please select your device type.</p>
        }
    </SpaceBetween>
    // Do you own a Bluetooth-enabled Omron or iHealth blood pressure monitor?
    const contentBpCuff: JSX.Element = <SpaceBetween size='s' direction='vertical'>
        <SpaceBetween size='m' direction='horizontal'>
            <div>Do you have a Bluetooth-enabled Omron or iHealth blood pressure monitor that you will use for the program?</div>
        </SpaceBetween>
        <RadioGroup value={bloodPressureMonitor}
            onChange={({ detail }) => {
                setBloodPressureMonitor(detail.value)
                FormData.instance.bloodPressureMonitor = detail.value
            }}
            items={
                FormData.instance.provideBpCuff ?
                    [
                        { value: 'Omron', label: 'Yes, I will use my Omron monitor' },
                        { value: 'iHealth', label: 'Yes, I will use my iHealth monitor' },
                        { value: 'No', label: 'No, please send me an Omron monitor' },
                        // bp_cuff_bluetooth_required == false -  "Manual"
                        ...(!FormData.instance.bpCuffBluetoothRequired ? [{ value: 'Manual', label: 'No, but I will use another blood pressure monitor and self-report my readings' }] : [])
                    ] : [
                        { value: 'Omron', label: 'Yes, I will use my Omron monitor' },
                        { value: 'iHealth', label: 'Yes, I will use my iHealth monitor' },
                        // bp_cuff_bluetooth_required == false -  "Manual"
                        ...(!FormData.instance.bpCuffBluetoothRequired ? [{ value: 'Manual', label: 'No, but I will use another blood pressure monitor and self-report my readings' }] : [])
                    ]
            } />
        {
            !(firstLoaded || bloodPressureMonitor.length > 0) && <p className='tip-text'>Please select your blood pressure monitor.</p>
        }
    </SpaceBetween>
    // Do you own a glucose meter?
    const contentGlucometer: JSX.Element = <SpaceBetween size='s' direction='vertical'>
        <SpaceBetween size='m' direction='horizontal'>
            <div>Do you have a finger-prick glucose monitor or a continuous glucose monitor (CGM) that you will use for the program?</div>
        </SpaceBetween>
        <RadioGroup value={glucometer}
            onChange={({ detail }) => {
                setGlucometer(detail.value)
                FormData.instance.glucometer = detail.value
            }}
            items={
                FormData.instance.provideGlucometer ?
                    [
                        { value: 'Manual', label: 'Yes, I will use my finger-prick glucose monitor' },
                        { value: 'FreeStyle Libre', label: 'Yes, I will use my FreeStyle Libre CGM' },
                        { value: 'No', label: 'No, please send me a FreeStyle Libre CGM' }
                    ] : [
                        { value: 'Manual', label: 'Yes, I will use my finger-prick glucose monitor' },
                        { value: 'FreeStyle Libre', label: 'Yes, I will use my FreeStyle Libre CGM' }
                    ]
            } />
        {
            !(firstLoaded || glucometer.length > 0) && <p className='tip-text'>Please select your glucose meter.</p>
        }
    </SpaceBetween>
    // Please enter your mailing address so that we can ship you the device(s)
    const contentAddress: JSX.Element = <SpaceBetween size='s' direction='vertical'>
        <SpaceBetween size='m' direction='horizontal'>
            <div>Please enter your mailing address so that we can ship you the device(s)</div>
        </SpaceBetween>
        <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
            <TextContent>
                <p>Address</p>
            </TextContent>
            <Input value={address} onChange={({ detail }) => {
                setAddress(detail.value)
                FormData.instance.address = detail.value
            }} placeholder='Address' />
        </Grid>
        {
        !(firstLoaded || address.length > 0) && <p className='tip-text'>Please enter your address.</p>
        }
        <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
            <TextContent>
                <p>City</p>
            </TextContent>
            <Input value={addressCity} onChange={({ detail }) => {
                setAddressCity(detail.value)
                FormData.instance.addressCity = detail.value
            }} placeholder='City' />
        </Grid>
        {
        !(firstLoaded || addressCity.length > 0) && <p className='tip-text'>Please enter your city.</p>
        }
        <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
            <TextContent>
                <p>State</p>
            </TextContent>
            <Input value={addressState} onChange={({ detail }) => {
                setAddressState(detail.value)
                FormData.instance.addressState = detail.value
            }} placeholder='State' />
        </Grid>
        {
        !(firstLoaded || addressState.length > 0) && <p className='tip-text'>Please enter your state.</p>
        }
        <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
            <TextContent>
                <p>Zip</p>
            </TextContent>
            <Input value={addressZipcode} onChange={({ detail }) => {
                setAddressZipcode(detail.value)
                FormData.instance.addressZipcode = detail.value
            }} placeholder='Zip code' />
        </Grid>
        {
        !(firstLoaded || addressZipcode.length > 0) && <p className='tip-text'>Please enter your zipcode.</p>
        }
    </SpaceBetween>
    
    return <div>
        {
            success &&
            <div className='glow-border page-container page-width-constrain'>
                {renderProviderLogo()}
            <SpaceBetween size='xl' direction='vertical'>
                <div className='page-container'>
                    <div>You have successfully registered your account.</div>
                    <div>Please see your email for the next step.</div>
                </div>
            </SpaceBetween>
            {poweredBy()}
            </div>
        }
        {!success && <div className='glow-border page-container page-width-constrain'>
            <SpaceBetween size='xl' direction='vertical'>
                {renderProviderLogo()}
                {/* blood pressure */}
                {FormData.instance.showBpCuff && contentHtnMed}

                {FormData.instance.showGlucometer && contentDmMed}

                {/* phone */}
                {contentPhone}

                {/* Device */}
                {FormData.instance.showDevice && contentDevice}

                {/* Blood Pressure monitor (bp cuff) */}
                {FormData.instance.showBpCuff && FormData.instance.defaultBpCuff == null && contentBpCuff}

                {/* Glucometer */}
                {FormData.instance.showGlucometer && FormData.instance.defaultGlucometer == null && contentGlucometer}

                {/* address */}
                {
                    (bloodPressureMonitor === 'No' || FormData.instance.deviceType == "No" || glucometer === 'No') &&
                    contentAddress
                }

                <div className='line-align-center'>
                    <Button className='line-items-padding' onClick={() => { gotoPage(PagesEnum.Second) } } disabled={submitWaiting}>Back</Button>
                    <Button variant='primary' className='line-items-padding' onClick={submit} disabled={submitWaiting}>
                        {
                            submitWaiting && <Spinner />
                        }
                        Submit
                    </Button>
                </div>

                {
                    submitFailed && <p className='tip-text'>Submission Failed.</p>
                }
                {poweredBy()}
            </SpaceBetween>
        </div>
        }
    </div>
}
