import { Button, Checkbox, Input, NonCancelableCustomEvent, SpaceBetween } from "@cloudscape-design/components";
import "../__pages.css"
import PhoneInput, { CountryData } from 'react-phone-input-2'
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css'
import cipraLogo from '../../res/cipra_logo.jpg'
import phsoLogo from "../../res/phso-logo.png"
import cdxLogo from "../../res/cdx-logo.png"
import h2050Logo from "../../res/h2050-logo.jpg"
import nhcLogo from "../../res/nhc-logo.jpg"
import rxdxLogo from "../../res/rxdx-logo.png"
import cabLogo from '../../res/cab_logo.png'
import { ChangeEvent, useEffect, useState } from "react";
import { DEBUG_MODE, FormData } from "../../data/FormData";
import { Terms } from "../../res/terms";
import { NewAccountPageTrigger, PagesEnum } from "../../data/PageTrigger";
import { useLocation } from 'react-router-dom';
import { BaseChangeDetail } from "@cloudscape-design/components/input/interfaces";
import $ from 'jquery';



export function CreateAccountEntryPage(): JSX.Element {

    // get current route: 
    // if "/register/:provider/:program", display the original version
    // if "/register2/:provider/:program", display the simpler version
    const location = useLocation();

    const [emailValue, setEmailValue] = useState<string>(FormData.instance.email)
    const [phoneValue, setPhoneValue] = useState<string>(FormData.instance.cell)
    const [passwordValue, setPasswordValue] = useState<string>(FormData.instance.password)
    const [confirmedPasswordValue, setConfirmedPasswordValue] = useState<string>(FormData.instance.password)
    const [policyCheckedValue, setPolicyCheckedValue] = useState<boolean>(false)
    const [passwordTip, setPasswordTip] = useState<string | undefined>('')
    const [termAgreed, setTermAgreed] = useState<boolean>(true)
    const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(true)
    const [requirementsMet, setRequirementsMet] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        digit: false,
        special:false
    });
    const termAgreement: JSX.Element = Terms()
    useEffect(() => {
        // Example: Add a click event listener to an element with class 'my-element'
        $('input[type=password]:first').keyup(function() {// only show the password requirement box when the password input is focused
            // keyup code here
           }).focus(function() {
            $('#pswd_info').show();
           }).blur(function() {
            $('#pswd_info').hide();
           });
      }, []); // Empty dependency array means this effect runs only once after initial render
      
    function handlePasswordChange(event: NonCancelableCustomEvent<BaseChangeDetail>): void {
        setPasswordValue(event.detail.value)
        checkPasswordRequirements(event.detail.value);
        setPasswordTip(undefined)
    }

    const checkPasswordRequirements = (password: string) => {
        setRequirementsMet({
            length: password.length >= 8 && password.length <= 25,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            digit: /[0-9]/.test(password),
            special:/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)
        });
    };

    function submit(): void {

        if (DEBUG_MODE) {
            // simpler version of registration
            // if(NewAccountPageTrigger.instance.setNewAccountShowPage && location.pathname.startsWith("/register2/")) {
            //     console.log("URL starts with '/register2/'");
            //     NewAccountPageTrigger.instance.setNewAccountShowPage(PagesEnum.Simple)
            // }
            if (NewAccountPageTrigger.instance.setNewAccountShowPage && location.pathname.startsWith("/register/")) {
                console.log("URL starts with '/register/'");
                NewAccountPageTrigger.instance.setNewAccountShowPage(PagesEnum.Second)
            }
            return
        }

        const checkedTermAgreed = policyCheckedValue
        const checkValidPhoneNumber = phoneValue && isPossiblePhoneNumber("+" + phoneValue) ? true : false
        setValidPhoneNumber(checkValidPhoneNumber)
        setTermAgreed(checkedTermAgreed)
        // check if password meet the requirement
        var passwordMeetRequirement = false
        if (passwordValue.length == 0) {
            setPasswordTip("Password cann't be empty.")
        } else if (passwordValue.length > 25) {
            setPasswordTip("Your password is too long. Please choose a password that is no more than 15 characters long")
        } else if (passwordValue.length < 8) {
            setPasswordTip("Your password is too short. Please choose a password that is at least 8 characters long.")
        } else if (! /[A-Z]/.test(passwordValue)) {
            setPasswordTip("Password must contain at least one uppercase letter.")
        } else if (!/[a-z]/.test(passwordValue)) {
            setPasswordTip("Password must contain at least one lowercase letter.")
        } else if (!/\d/.test(passwordValue)) {
            setPasswordTip("Password must contain at least one numeric digit.")
        } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(passwordValue)) {
            setPasswordTip("Password must contain at least one special character.")
        } else if (passwordValue !== confirmedPasswordValue) {
            setPasswordTip("Password not the same")
        } else { // all requirements are meet
            setPasswordTip(undefined)
            passwordMeetRequirement = true
        }

        if (DEBUG_MODE || (checkedTermAgreed && checkValidPhoneNumber && passwordMeetRequirement)) {
            FormData.instance.email = emailValue
            FormData.instance.cell = phoneValue
            FormData.instance.password = passwordValue
            // simpler version of registration
            if(NewAccountPageTrigger.instance.setNewAccountShowPage && location.pathname.startsWith("/register2/")) {
                NewAccountPageTrigger.instance.setNewAccountShowPage(PagesEnum.Simple)
            }
            if (NewAccountPageTrigger.instance.setNewAccountShowPage && location.pathname.startsWith("/register/")) {
                NewAccountPageTrigger.instance.setNewAccountShowPage(PagesEnum.Second)
            }
        }
    }

    function handlePhoneNumberChange(value: string, data: {} | CountryData, event: ChangeEvent<HTMLInputElement>, formattedValue: string): void {
        setPhoneValue(value)
        setValidPhoneNumber(true)
    }

    
    function handleConfirmedPasswordChange(event: NonCancelableCustomEvent<BaseChangeDetail>): void {
        setConfirmedPasswordValue(event.detail.value)
        setPasswordTip(undefined)
    }
    const renderProviderLogo = () => {
        const provider = FormData.instance.provider.toUpperCase();
        if (provider === "CDX") {
            return <img src={cdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "H2050") {
            return <img src={h2050Logo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "NHC") {
            return <img src={nhcLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "PHSO") {
            return <img src={phsoLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "RXDX") {
            return <img src={rxdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "CAB"){
            return <img src={cabLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else {
            return <img src={cipraLogo} alt='provider logo' width='90%' className='line-align-center' />;
        }
    };
    const poweredBy = () => {
        const provider = FormData.instance.provider.toUpperCase();
        if (provider === "CDX" || provider === "H2050" || provider === "NHC" || provider === "PHSO" || provider === "RXDX" || provider == "CAB") {
            return (
              <div className='line-align-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p style={{ marginRight: '10px' }}>Enabled by:</p>
                <img src={cipraLogo} alt="Description" style={{ width: 'auto', maxWidth: '100%', height: '30px' }} />
              </div>
            );
          } else {
            return null;
          }
    }
    return <div className="glow-border page-container" style={{ width: "330px" }}>
        <SpaceBetween size='xs' direction='vertical'>
            {renderProviderLogo()}
            {(FormData.instance.program.split("_")[0].toUpperCase() === "HTN")  &&
                <div>
                    <h3 className='line-align-center' style={{ marginBottom: '0' }}>Hypertension Remote Monitoring </h3>
                    <h3 className='line-align-center' style={{ margin: '0 0' }}> + Lifestyle Guidance </h3>
                </div>

            }
            
            {(FormData.instance.program.split("_")[0].toUpperCase() === "DM") &&
                <div>
                    <h3 className='line-align-center' style={{ marginBottom: '0' }}>Diabetes Remote Monitoring </h3>
                    <h3 className='line-align-center' style={{ margin: '0 0' }}> + Lifestyle Guidance </h3>
                </div>
            }
            
            <h2 className='line-align-center'>Create an account</h2>
            <SpaceBetween size='m' direction='horizontal'>
                <div>Email</div>
            </SpaceBetween>
            <Input onChange={({ detail }) => setEmailValue(detail.value)}
                value={emailValue}
                type="email"
                disabled={true}
            ></Input>
            <SpaceBetween size='m' direction='horizontal'>
                <div>Cell</div>
            </SpaceBetween>
            <PhoneInput
                country={'us'}
                value={phoneValue}
                onChange={handlePhoneNumberChange}
                buttonStyle={{
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    borderTop: '2px solid #9ba7b6',
                    borderBottom: '2px solid #9ba7b6',
                    borderLeft: '2px solid #9ba7b6'
                }}
                inputStyle={{
                    width: '100%',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderTop: '2px solid #9ba7b6',
                    borderBottom: '2px solid #9ba7b6',
                    borderRight: '2px solid #9ba7b6'
                }}
                placeholder="Cellphone number"

            />
            {
                !validPhoneNumber && <p className='tip-text'>Please enter a valid phone number.</p>
            }
            <SpaceBetween size='m' direction='horizontal'>
                <div>Password</div>
            </SpaceBetween>
            <Input onChange={handlePasswordChange}
                value={passwordValue}
                type="password"
                placeholder="Password"
            ></Input>
            <div id="pswd_info">
                {/* Display password requirements */}
                <h4>Password must meet the following requirements:</h4>
                <ul className="password-requirements">
                    <li className={requirementsMet.length ? 'met' : 'not-met'}>At least 8 characters. At most 25 characters</li>
                    <li className={requirementsMet.uppercase ? 'met' : 'not-met'}>At least one uppercase letter</li>
                    <li className={requirementsMet.lowercase ? 'met' : 'not-met'}>At least one lowercase letter</li>
                    <li className={requirementsMet.digit ? 'met' : 'not-met'}>At least one digit</li>
                    <li className={requirementsMet.special ? 'met' : 'not-met'}>At least one special character</li>
                </ul>
            </div>
            <SpaceBetween size='m' direction='horizontal'>
                <div>Confirmed Password</div>
            </SpaceBetween>
            <Input onChange={handleConfirmedPasswordChange}
                value={confirmedPasswordValue}
                type="password"
                placeholder="Confirmed Password"
            ></Input>
            {
                (passwordTip) && <p className='tip-text'>{passwordTip}</p>
            }
            <SpaceBetween size='m' direction='horizontal'>
                <div>Terms of Service</div>
            </SpaceBetween>
            <div style={{
                height: '150px',
                overflow: 'auto',
                borderRadius: '8px',
                border: '2px solid #9ba7b6'
            }}>
                {termAgreement}
            </div>
            <Checkbox onChange={({ detail }) => setPolicyCheckedValue(detail.checked)}
                checked={policyCheckedValue}>
                I have read and agree to the Terms and Conditions, including the <a href={'https://www.cipra.ai/privacy-policy'} target={'_blank'} rel="noreferrer">Privacy Policy</a>. I agree to receive SMS messages related to the service.
            </Checkbox>
            {
                !termAgreed && <p className='tip-text'>You must agree with the Terms of Service.</p>
            }

            <div className='line-align-center'>
                <Button variant='primary' onClick={submit}>
                    Join
                </Button>
            </div>
            {poweredBy()}
        </SpaceBetween>
    </div>
}
