import { Button, Input, RadioGroup, SpaceBetween } from "@cloudscape-design/components";
import { useState } from "react";
import { CheckboxGroup, CheckboxGroupIsEmpty, CheckboxItem } from "../../components/CheckboxGroup";
import { DEBUG_MODE, FormData } from "../../data/FormData";
import { NewAccountPageTrigger, PagesEnum } from "../../data/PageTrigger";
import { log } from "console";
import cipraLogo from '../../res/cipra_logo.jpg'
import phsoLogo from "../../res/phso-logo.png"
import cdxLogo from "../../res/cdx-logo.png"
import h2050Logo from "../../res/h2050-logo.jpg"
import nhcLogo from "../../res/nhc-logo.jpg"
import rxdxLogo from "../../res/rxdx-logo.png"

export function QuestionnairePage1(): JSX.Element {

    // const [provider, setProvider] = useState(FormData.instance.provider)
    const [firstName, setFirstName] = useState(FormData.instance.firstName)
    const [lastName, setLastName] = useState(FormData.instance.lastName)
    const [genderValue, setGenderValue] = useState(FormData.instance.gender)
    const [ageValue, setAgeValue] = useState<string>(FormData.instance.age)
    const [heightFeetValue, setHeightFeetValue] = useState<string>(FormData.instance.heightFeet)
    const [heightInchesValue, setHeightInchesValue] = useState<string>(FormData.instance.heightInches)
    const [weightValue, setWeightValue] = useState<string>(FormData.instance.weight)
    const [zipCodeValue, setZipCodeValue] = useState<string>(FormData.instance.zipCode)
    const [preexistingConditionsValue, setPreexistingConditionsValue] = useState<CheckboxItem[]>(FormData.instance.preexistingCondition)
    const [raceValue, setRaceValue] = useState<CheckboxItem[]>(FormData.instance.race)
    const [customRace, setCustomRace] = useState<string>(FormData.instance.otherRace)

    const [firstLoaded, setFirstLoaded] = useState<boolean>(true)

    function gotoPage(page: PagesEnum) {
        if (NewAccountPageTrigger.instance.setNewAccountShowPage) {
            NewAccountPageTrigger.instance.setNewAccountShowPage(page)
        }
    }
    function nextPage() {
        gotoPage(PagesEnum.Third)
    }
    //change weight question to kg instead of lbs for India registration
    function weightUnit() {
        const program = FormData.instance.program
        const country = program.substring(program.length - 2, program.length).toUpperCase()
        if (country === "IN") {
            return "KG"
        } else {
            return "LB"
        }
    }

    function submit(): void {
        if (DEBUG_MODE) {
            nextPage()
            return
        }
        setFirstLoaded(false)
        if (firstName.length > 0 && lastName.length > 0 && genderValue.length > 0 && ageValue.length > 0
            && heightFeetValue.length > 0 && heightInchesValue.length > 0 && zipCodeValue.length > 0 && weightValue.length > 0
            && !CheckboxGroupIsEmpty(preexistingConditionsValue)
            && !CheckboxGroupIsEmpty(raceValue)
        ) {
            nextPage()
        }
    }
    const renderProviderLogo = () => {
        const provider = FormData.instance.provider.toUpperCase();
        if (provider === "CDX") {
            return <img src={cdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "H2050") {
            return <img src={h2050Logo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "NHC") {
            return <img src={nhcLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "PHSO") {
            return <img src={phsoLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else if (provider === "RXDX") {
            return <img src={rxdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
        } else {
            return <img src={cipraLogo} alt='provider logo' width='90%' className='line-align-center' />;
        }
    };
    const poweredBy = () => {
        const provider = FormData.instance.provider.toUpperCase();
        if (provider === "CDX" || provider === "H2050" || provider === "NHC" || provider === "PHSO" || provider === "RXDX") {
            return (
                <div className='line-align-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ marginRight: '10px' }}>Enabled by:</p>
                    <img src={cipraLogo} alt="Description" style={{ width: 'auto', maxWidth: '100%', height: '30px' }} />
                </div>
            );
        } else {
            return null;
        }
    }
    return <div className='glow-border page-container page-width-constrain'>
        <SpaceBetween size='xl' direction='vertical'>
            {renderProviderLogo()}
            <SpaceBetween size='s' direction='vertical'>
                <SpaceBetween size='m' direction='horizontal'>
                    <div>Name</div>
                </SpaceBetween>
                <SpaceBetween size='m' direction='horizontal'>
                    <Input onChange={({ detail }) => {
                        setFirstName(detail.value)
                        FormData.instance.firstName = detail.value
                    }}
                        value={firstName}
                        placeholder="First Name"
                    ></Input>
                    <Input onChange={({ detail }) => {
                        setLastName(detail.value)
                        FormData.instance.lastName = detail.value
                    }}
                        value={lastName}
                        placeholder="Last Name"
                    ></Input>
                </SpaceBetween>
                {
                    !(firstLoaded || (firstName.length > 0 && lastName.length > 0)) && <p className='tip-text'>Please enter your full name.</p>
                }
            </SpaceBetween>

            <SpaceBetween size='s' direction='vertical'>
                <SpaceBetween size='m' direction='horizontal'>
                    <div>What is your Gender?</div>
                </SpaceBetween>
                <RadioGroup
                    onChange={({ detail }) => {
                        setGenderValue(detail.value)
                        FormData.instance.gender = detail.value
                    }}
                    value={genderValue}
                    items={[
                        { value: "male", label: "Male" },
                        { value: "female", label: "Female" },
                        { value: "preferNotToSay", label: "Prefer not to say" }
                    ]}
                />
                {
                    !(firstLoaded || genderValue.length > 0) && <p className='tip-text'>Please select your gender.</p>
                }
            </SpaceBetween>

            <SpaceBetween size='s' direction='vertical'>
                <SpaceBetween size='m' direction='horizontal'>
                    <div>How would you best describe yourself? (multiple select)</div>
                </SpaceBetween>
                <CheckboxGroup content={raceValue} setContent={
                    (items: CheckboxItem[]) => {
                        setRaceValue(items)
                        FormData.instance.race = items
                    }
                }></CheckboxGroup>
                {
                    !(firstLoaded || !CheckboxGroupIsEmpty(raceValue)) && <p className='tip-text'>Please select your race.</p>
                }
                {
                    !(raceValue.filter(item => (item.key === "Other" && item.checked)).length === 0) &&
                    <Input value={customRace} onChange={({ detail }) => {
                        setCustomRace(detail.value)
                        FormData.instance.otherRace = detail.value
                    }} />
                }
            </SpaceBetween>

            {/*age*/}
            <SpaceBetween size='s' direction='vertical'>
                <SpaceBetween size='m' direction='horizontal'>
                    <div>Please select your age (0-100)</div>
                </SpaceBetween>
                <Input
                    onChange={({ detail }) => {
                        if (isNaN(Number(detail.value))) return
                        const ageNum: number = Number(detail.value)
                        if (ageNum >= 0 && ageNum <= 100) {
                            setAgeValue(detail.value)
                            FormData.instance.age = detail.value
                        }
                    }}
                    value={ageValue}
                    type="number"
                    placeholder='Age'
                />
                {
                    !(firstLoaded || ageValue.length > 0) && <p className='tip-text'>Please enter your age.</p>
                }
            </SpaceBetween>

            {/*height*/}
            <SpaceBetween size='s' direction='vertical'>
                <SpaceBetween size='m' direction='horizontal'>
                    <div>Please select your height</div>
                </SpaceBetween>
                <SpaceBetween size='m' direction='horizontal'>
                    <Input
                        onChange={({ detail }) => {
                            if (isNaN(Number(detail.value))) return
                            const ageNum: number = Number(detail.value)
                            if (ageNum >= 0 && ageNum <= 7) {
                                setHeightFeetValue(detail.value)
                                FormData.instance.heightFeet = detail.value
                            }
                        }}
                        value={heightFeetValue}
                        type="number"
                        placeholder='Feet'
                    />
                    <Input
                        onChange={({ detail }) => {
                            if (isNaN(Number(detail.value))) return
                            const ageNum: number = Number(detail.value)
                            if (ageNum >= 0 && ageNum <= 11) {
                                setHeightInchesValue(detail.value)
                                FormData.instance.heightInches = detail.value
                            }
                        }}
                        value={heightInchesValue}
                        type="number"
                        placeholder='Inches'
                    />
                </SpaceBetween>
                {
                    !(firstLoaded || (heightFeetValue.length > 0 && heightInchesValue.length > 0)) &&
                    <p className='tip-text'>Please enter your height.</p>
                }
            </SpaceBetween>

            {/*weight*/}
            <SpaceBetween size='s' direction='vertical'>
                <SpaceBetween size='m' direction='horizontal'>
                    <div>Please select your weight</div>
                </SpaceBetween>
                <SpaceBetween size='m' direction='horizontal'>
                    <Input
                        onChange={({ detail }) => {
                            if (isNaN(Number(detail.value))) return
                            const ageNum: number = Number(detail.value)
                            if (ageNum >= 0 && ageNum <= 400) {
                                setWeightValue(detail.value)
                                FormData.instance.weight = detail.value
                            }
                        }}
                        value={weightValue}
                        type="number"
                        placeholder={weightUnit()}
                    />
                </SpaceBetween>
                {
                    !(firstLoaded || weightValue.length > 0) &&
                    <p className='tip-text'>Please enter your weight.</p>
                }
            </SpaceBetween>

            {/*zip code*/}
            <SpaceBetween size='s' direction='vertical'>
                <SpaceBetween size='m' direction='horizontal'>
                    <div>Please enter your zip code</div>
                </SpaceBetween>
                <Input
                    onChange={({ detail }) => {
                        setZipCodeValue(detail.value)
                        FormData.instance.zipCode = detail.value
                    }}
                    value={zipCodeValue}
                />
                {
                    !(firstLoaded || zipCodeValue.length > 0) && <p className='tip-text'>Please enter your zip code.</p>
                }
            </SpaceBetween>

            {/* preexisting conditions */}
            <SpaceBetween size='s' direction='vertical'>
                <SpaceBetween size='m' direction='horizontal'>
                    <div>Please select any preexisting conditions you have</div>
                </SpaceBetween>
                <CheckboxGroup content={preexistingConditionsValue} setContent={
                    (items: CheckboxItem[]) => {
                        setPreexistingConditionsValue(items)
                        FormData.instance.preexistingCondition = items
                    }
                }></CheckboxGroup>
                {
                    !(firstLoaded || !CheckboxGroupIsEmpty(preexistingConditionsValue)) && <p className='tip-text'>Please select preexisting conditions.</p>
                }
            </SpaceBetween>

            <div className='line-align-center'>
                <Button className='line-items-padding' onClick={() => { gotoPage(PagesEnum.First) }}>Back</Button>
                <Button variant='primary' className='line-items-padding' onClick={submit}>Next</Button>
            </div>
            {poweredBy()}
        </SpaceBetween>
    </div>
}
