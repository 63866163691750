import cipraLogo from "../../res/cipra_logo.jpg";
import { Button, Input, SpaceBetween, Spinner } from "@cloudscape-design/components";
import { useEffect, useState, useCallback } from "react";
import { AuthData } from "../../data/AuthData";
import { RegistrationData } from "../../data/RegistrationData";
import {support_contact} from "../../res/support"
const support: JSX.Element = support_contact
export function GenerateRegistrationURLEntryPage(): JSX.Element {

    const [emailValue, setEmailValue] = useState<string>('')
    const [passwordValue, setPasswordValue] = useState<string>('')
    const [signInTip, setSignInTip] = useState<JSX.Element | undefined>(undefined)
    const [selectionTip, setSelectionTip] = useState<JSX.Element | undefined>(undefined)

    const [signedIn, setSignedIn] = useState<boolean>(false)
    const [submitWaiting, setSubmitWaiting] = useState<boolean>(false)
    const [response, setResponse] = useState<JsonData | null>(null)
    const [selectedProvider, setSelectedProvider] = useState<string>('')
    const [selectedProgram, setSelectedProgram] = useState<string>('')
    const [selectedLanguage, setSelectedLanguage] = useState<string>('')
    const [newEmailValue, setNewEmailValue] = useState<string>('')
    const [subscription, setSubscription] = useState<string>('')

    // define the type of jsonData using an interface accroding to the API response
    interface JsonData {
        providers: { provider: string, name: string }[];
        programs: { program: string, name: string, country: string }[];
        languages:{language_id: string, language_name:string}[];
    }
    const submit = useCallback((): void => {
        setSubmitWaiting(true)
        // check if the input box is empty
        if (emailValue.length === 0) {
            setSubmitWaiting(false)
            setSignInTip(
                <div>
                    <p>Email can't be empty</p>
                </div>
            )
            return
        }
        if (passwordValue.length === 0) {
            setSubmitWaiting(false)
            setSignInTip(
                <div>
                    <p>Password can't be empty</p>
                </div>
            )
            return
        }
        // if both input boxs are not empty, call api to validate credentials
        AuthData.instance.userAuth(
            emailValue,
            passwordValue,
            (data) => {
                setResponse(data)
                setSubmitWaiting(false)
                setSignedIn(true)
            },   // send email
            () => {
                setSubmitWaiting(false)
                setSignInTip(
                    <div>
                        <p>Log In Failed</p>
                        {support}
                    </div>
                )
            }
        )

    }, [emailValue, passwordValue])

    const selectionSubmit = useCallback((): void => {
        console.log(selectedProgram)
        console.log(selectedProvider)
        console.log(newEmailValue)
        console.log(selectedLanguage)
        // check is the input field is empty
        
        if (selectedProvider.length === 0) {
            setSubmitWaiting(false)
            setSelectionTip(
                <div>
                    <p>Please select a provider</p>
                </div>
            )
            return
        }
        if (selectedProgram.length === 0) {
            setSubmitWaiting(false)
            setSelectionTip(
                <div>
                    <p>Please select a program</p>
                </div>
            )
            return
        }
        if(selectedLanguage.length === 0){
            setSubmitWaiting(false)
            setSelectionTip(
                <div>
                    <p>Please select a language</p>
                </div>
            )
            return
        }
        if (newEmailValue.length === 0) {
            setSubmitWaiting(false)
            setSelectionTip(
                <div>
                    <p>Email can't be empty</p>
                </div>
            )
            return
        }
        RegistrationData.instance.emailExistv2(
            newEmailValue,
            () => {
                RegistrationData.instance.sendEmail(
                    newEmailValue,
                    selectedProvider,
                    selectedProgram,
                    subscription,
                    selectedLanguage,
                    () => {
                        setSubmitWaiting(false)
                        setSelectionTip(
                            <div style={{color: 'green'}}>
                                Registration Link has been sent to <b>{newEmailValue}</b>.
                            </div>
                        )
                    },
                    () => {
                        setSubmitWaiting(false)
                        setSelectionTip(
                            <div>
                                Request cannot be processed at the moment.
                            </div>
                        )
                    }
                )
            },
            // Email already registered.
            () => {
                setSubmitWaiting(false)
                setSelectionTip(
                    <div>
                        <p>This email address <b>{newEmailValue} has already been registered.</b></p>
                        {support}
                    </div>
                )
            }
        )
    }, [selectedProgram, selectedProvider, newEmailValue, subscription, selectedLanguage])

    useEffect(() => {

        const enterKeyDown = (event: KeyboardEvent) => {
            if (event.key !== 'Enter') return
            submit()
        }

        document.addEventListener("keydown", enterKeyDown, false);

        return () => {
            document.removeEventListener("keydown", enterKeyDown, false);
        };
    }, [submit]);

    function handleProviderChange(value: string): void {
        setSelectedProvider(value)
    }
    function handleProgramChange(value: string): void {
        setSelectedProgram(value)
    }
    function handleSubscriptionChange(value: string) : void{
        setSubscription(value)
    }
    function handleLanguageChange(value:string):void{
        setSelectedLanguage(value)
    }

    return <div className="center-screen">
        <div className="glow-border page-container" style={{ maxWidth: "330px" }}>
            <SpaceBetween size='m' direction='vertical'>
                <img src={cipraLogo} alt='cipra logo' width='90%' className='line-align-center' />
                {
                    (!signedIn) &&
                    <SpaceBetween size='m' direction='vertical'>
                        <h2 className='line-align-center'>Log In</h2>
                        {/* Email input box */}
                        <SpaceBetween size='m' direction='horizontal'>
                            <div>Email</div>
                        </SpaceBetween>
                        <Input onChange={({ detail }) => setEmailValue(detail.value)}
                            value={emailValue}
                            type="email"
                            placeholder="Email Address"
                        ></Input>
                        {/* Password input box */}
                        <SpaceBetween size='m' direction='horizontal'>
                            <div>Password</div>
                        </SpaceBetween>
                        <Input onChange={({ detail }) => setPasswordValue(detail.value)}
                            value={passwordValue}
                            type="password"
                            placeholder="Password"
                        ></Input>
                        {/* Sign in tip box */}
                        {(signInTip) && <p className='tip-text'>{signInTip}</p>}

                        <div className='line-align-center'>
                            <Button variant='primary' onClick={submit} disabled={submitWaiting}>
                                {
                                    submitWaiting && <Spinner />
                                }
                                Log In
                            </Button>
                        </div>
                    </SpaceBetween>
                }
                {(signedIn) && (response) &&
                    <SpaceBetween size='m' direction='vertical'>
                        <SpaceBetween size='m' direction='vertical'>
                            <SpaceBetween size='m' direction='horizontal'>
                                <div>Select a Provider</div>
                            </SpaceBetween>
                            <select id="providerSelect" onChange={(e) => handleProviderChange(e.target.value)}
                            style={{ color: 'black', padding: '7px', borderRadius: '7px', width: '100%', border: '2px solid gray'}}>
                                <option value=""></option>
                                {response.providers.map(provider => (
                                    <option key={provider.provider} value={provider.provider}>{provider.name}</option>
                                ))}
                            </select>
                        </SpaceBetween>
                        <SpaceBetween size='m' direction='vertical'>
                            <SpaceBetween size='m' direction='horizontal'>
                                <div>Select a Program</div>
                            </SpaceBetween>
                            <select id="programSelect" onChange={(e) => handleProgramChange(e.target.value)}
                            style={{ color: 'black', padding: '7px', borderRadius: '7px' , width: '100%', border: '2px solid gray'}}>
                                <option value=""></option>
                                {response.programs.map(program => (
                                    <option key={program.program} value={program.program}>{program.name} ({program.program}) </option>
                                ))}
                            </select>
                        </SpaceBetween>
                        <SpaceBetween size='m' direction='vertical'>
                            <SpaceBetween size='m' direction='horizontal'>
                                <div>Select a Language</div>
                            </SpaceBetween>
                            <select id="languageSelect" onChange={(e) => handleLanguageChange(e.target.value)}
                            style={{ color: 'black', padding: '7px', borderRadius: '7px' , width: '100%', border: '2px solid gray'}}>
                                <option value=""></option>
                                {response.languages.map(languages => (
                                    <option key={languages.language_id} value={languages.language_id}>{languages.language_name}</option>
                                ))}
                            </select>
                        </SpaceBetween>
                        {emailValue === "support.in@cipra.ai" &&(
                            <SpaceBetween size='m' direction='vertical'>
                            <SpaceBetween size='m' direction='horizontal'>
                                <div>Select Subscription Type</div>
                            </SpaceBetween>
                            <select id="subscriptionSelect" onChange={(e) => handleSubscriptionChange(e.target.value)}
                            style={{ color: 'black', padding: '7px', borderRadius: '7px' , width: '100%', border: '2px solid gray'}}>
                                <option value=""></option>
                                <option value="6">6 months</option>
                                <option value="12">12 months</option>
                            </select>
                            </SpaceBetween>)
                        }
                        
                        <SpaceBetween size='m' direction='vertical'>
                            <SpaceBetween size='m' direction='horizontal'>
                                <div>Enter Patient's Email</div>
                            </SpaceBetween>
                            <Input onChange={({ detail }) => setNewEmailValue(detail.value)}
                                value={newEmailValue}
                                type="email"
                                placeholder="Email Address"
                            ></Input>
                        </SpaceBetween>
                        {/* selection tip box */}
                        {(selectionTip) && <p className='tip-text'>{selectionTip}</p>}
                        <div className='line-align-center'>
                            <Button variant='primary' onClick={selectionSubmit} disabled={submitWaiting}>
                                {
                                    submitWaiting && <Spinner />
                                }
                                Confirm
                            </Button>
                        </div>
                    </SpaceBetween>
                }

            </SpaceBetween>
        </div>
    </div>
}